import { Grid2, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomTabPanel } from '../../DadosPessoais/accordionAndTabPanel';
import { useEventosActions } from '../../../states/eventos/eventos.actions';
import { useEventosParticipantesActions } from '../../../states/eventos/eventosParticipantes.actions';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { BUsEnum, IEvento, IEventoParticipante } from '../../../commons/types/eventos';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { authAtom } from '../../../states/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import ProximosEventos from './ProximosEventos';
import { toast } from 'react-toastify';
import EventosInscritos from './EventosInscritos';

dayjs.extend(customParseFormat);

interface IEventosQuery {
  tipo: string;
  dataInicio: Date | Dayjs | null;
  dataFim: Date | Dayjs | null;
  apenasAtivos: boolean;
  bu: BUsEnum | null;
}

interface IEventosParticipantesQuery {
  tipo: string;
  idEvento: number;
  idAssessorado: number;
  incluirEvento: boolean;
}

export interface IEventoPage {
  tipo: string;
}

const EventosInternos: React.FC<IEventoPage> = ({ tipo }) => {
  const auth = useRecoilValue(authAtom);
  const [selectedTab, setSelectedTab] = useState(0);
  const actions = useEventosActions();
  const participantesActions = useEventosParticipantesActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [eventos, setEventos] = useState<IEvento[]>([]);
  const [eventosInscritos, setEventosInscritos] = useState<IEventoParticipante[]>([]);
  const [eventosQuery, setEventosQuery] = useState<IEventosQuery>({
    tipo: tipo,
    dataInicio: null,
    dataFim: null,
    apenasAtivos: true,
    bu: auth?.comanda?.bUs ?? null,
  });
  const [eventosParticipantesQuery, setEventosParticipantesQuery] =
    useState<IEventosParticipantesQuery>({
      idEvento: 0,
      idAssessorado: auth!.id ?? 0,
      incluirEvento: true,
      tipo: tipo,
    });

  const loadData = async () => {
    setLoading(true);
    const resp = await actions.list(false, makeQueryParams());
    const participantes = await participantesActions.list(false, makeInscritosQueryParams());
    setEventos(
      resp.filter(
        (e: IEvento) => !participantes.find((p: IEventoParticipante) => p.idEvento == e.id),
      ),
    );
    setEventosInscritos(participantes);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChangeTab = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDateChange = (field: 'dataInicio' | 'dataFim', value: dayjs.Dayjs | null) => {
    setEventosQuery((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const makeQueryParams = () => {
    const queryParams = new URLSearchParams();
    if (eventosQuery.tipo) {
      queryParams.set('tipo', eventosQuery.tipo);
      queryParams.set('apenasAtivos', 'true');
      queryParams.set('dataFim', eventosQuery?.dataFim?.toISOString() || 
        dayjs().startOf('day').toISOString());

      if (eventosQuery.dataInicio)
        queryParams.set('dataInicio', eventosQuery.dataInicio.toISOString());

      if (eventosQuery.bu !== null) queryParams.set('bu', eventosQuery.bu.toString());

      return `?${queryParams.toString()}`;
    }
  };

  const makeInscritosQueryParams = () => {
    const queryParams = new URLSearchParams();
    queryParams.set('idAssessorado', eventosParticipantesQuery.idAssessorado.toString());
    queryParams.set('incluirEvento', eventosParticipantesQuery.incluirEvento.toString());
    return `?${queryParams.toString()}`;
  };

  const handleFilterEvents = async () => {
    loadData();
  };

  const inscrever = async (id: number) => {
    const request: IEventoParticipante = {
      idEvento: id,
      idContato: auth?.id,
      nome: auth?.nome ?? '',
      email: auth?.email ?? '',
      telefone: auth?.telefone ?? '',
      id: 0,
    };
    await participantesActions.create(request);
    toast.success('Inscrição realizada com sucesso');
    loadData();
  };

  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }}>
        <div>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Próximos eventos" />
            <Tab label="Eventos inscritos" />
          </Tabs>
        </div>
      </Grid2>

      <Grid2>
        <CustomTabPanel value={selectedTab} index={0}>
          <ProximosEventos
            eventos={eventos}
            eventosQuery={eventosQuery}
            loading={loading}
            handleDateChange={handleDateChange}
            handleFilterEvents={handleFilterEvents}
            inscrever={inscrever}
          />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={1}>
          <EventosInscritos
            eventosInscritos={eventosInscritos}
            eventosQuery={eventosQuery}
            loading={loading}
            handleDateChange={handleDateChange}
            handleFilterEvents={handleFilterEvents}
            inscrever={inscrever}
          />
        </CustomTabPanel>
      </Grid2>
    </Grid2>
  );
};

export default EventosInternos;
