
import { useBaseOneActions } from "../base.actionsOne";
import { contatoHistoricoEmpresaPosicoesAtom } from "./ContatoHistoricoEmpresaPosicaoAtom";

function useContatoHistoricoEmpresaPosicoesActions() {
  const baseActions = useBaseOneActions('contatoHistoricoEmpresaPosicoes', contatoHistoricoEmpresaPosicoesAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  }
}

export { useContatoHistoricoEmpresaPosicoesActions };

