import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from 'styled-components';
import React from 'react';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer, TextFieldStato } from '../../commons/styleds/styleds';
import { CircularProgress, Divider, FormControl, InputAdornment, InputLabel } from '@mui/material';
import { IAuth } from '../../commons/types/usuatios';
import { useUsuarioActions } from '../../states/usuarios/usuario.actions';
import EnderecosComponent from './Enderecos';
import TelefonesComponent from './Telefones';
import FormacaoAcademicaComponent from './FormacaoAcademica';
import IdiomasComponent from './Idiomas';
import HistoricoProfissionalComponent from './HistoricoProfissional';
import ImgBackground from '../../assets/intoo_E.png';
import SaveIcon from '../../assets/iconsUX/Icon.png';
import EditIcon from '../../assets/iconsUX/edit-3.png';
import ChevronRight from '../../assets/iconsUX/chevron-right.png';
import Home from '../../assets/iconsUX/home.png';
import Link from "@mui/material/Link";
import { history } from '../../helpers/history';
import { NumericFormat } from 'react-number-format';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


const DadosPessoais: React.FC = () => {
    const auth = useRecoilValue(authAtom);
    const usuarioActions = useUsuarioActions();
    const [load, setLoad] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [editar, setEditar] = useState<boolean>(false);
    const [assessorado, setAssessorado] = useState<IAuth>();

    const editarCss = {
        backgroundColor: "white",
        borderRadius: "20px",
        marginTop: "20px !important",
    }

    const handleChangeNext = (e: any) => {
        setLoadingSave(true);
        usuarioActions.update(assessorado!.id!, assessorado!).then(() => {
            setTimeout(() => {
                setLoadingSave(false);
            }, 5000);
            setEditar(false);
            toast.success("Salvo com sucesso !");

        }).catch((erro) => {
            toast.warn(erro);
            setLoadingSave(false);
        });
    };

    const handleEditar = (e: any) => {
        setEditar(!editar);
    };


    useEffect(() => {
        setAssessorado(auth!);
        usuarioActions.get(auth?.id!).then((resp: any) => {
            setAssessorado(resp);
            setLoad(false);
        }).catch((err: any) => {
            toast.warn(err)
            setLoad(false);
        });

    }, [auth]);

    const handleClick = (event: any) => {
        event.preventDefault();
        history.push('/');
    };

    return (
        <GridContainer container direction="row" spacing={0} sx={{ display: "flex", paddingLeft: "30px !important", backgroundColor: "#f2f2f2" }}>
            <DivMain>

                <NavPage>
                    <Link
                        href="/minha-transicao"
                        underline="hover"
                        color="inherit"
                        onClick={handleClick}
                        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    >
                        <img src={Home} style={{ marginRight: "10px", width: "13px", height: "13px" }} />
                    </Link>

                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    >
                        <img src={ChevronRight} style={{ marginRight: "10px", width: "15px", height: "15px" }} />
                        Minha transição
                    </Link>

                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    >
                        <img src={ChevronRight} style={{ marginRight: "10px", marginLeft: "10px", width: "15px", height: "15px" }} />
                        Dados pessoais
                    </Link>
                </NavPage>

                <Grid xs={12} md={12} sx={{ display: "flex", paddingBottom: "30px" }}>
                    <Grid xs={9} md={6}>
                        <DadosPessoaisTitulo>
                            Dados Pessoais
                        </DadosPessoaisTitulo>
                    </Grid>
                    <Grid xs={3} md={3} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", cursor: "pointer" }}>
                        {editar ?
                            <ButtonHome
                                variant="contained"
                                disabled={loadingSave}
                                onClick={handleChangeNext}
                            >
                                {loadingSave ?
                                    <CircularProgress size={20} sx={{
                                        marginRight: "10px",
                                        color: "#b9b9b9"
                                    }} /> :
                                    <img src={SaveIcon} style={{ marginRight: "10px", width: "15px" }} />
                                }
                                Salvar
                            </ButtonHome> :
                            <TituloEditar onClick={handleEditar}>
                                <img src={EditIcon} style={{ marginRight: "5px", height: "20px", width: "20px" }} /> Editar
                            </TituloEditar>
                        }
                    </Grid>
                    <Grid md={3}>

                    </Grid>
                    <Divider orientation="horizontal" />
                </Grid>

                {!load ?
                    <Grid container xs={12} md={12}>

                        <Grid container xs={12} md={12} sx={{ marginTop: "10px" }}>
                            <SubTitulo>
                                Sobre Você
                            </SubTitulo>
                        </Grid>

                        <Grid container xs={12} md={9} sm={12} sx={{ padding: "5px 0px 0px 0px", alignItems: "flex-start" }}>
                            <Grid container xs={12} md={5} sx={{ padding: "0px 10px 0px 0px" }}>
                                <Grid container xs={12} md={12}>
                                    <LabelInput>Primeiro Nome</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <FormInput sx={editar ? editarCss : {}}
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    nome: e.target.value
                                                });
                                            }}
                                            value={assessorado?.nome}
                                            disabled={!editar}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container xs={12} md={12}>
                                    <LabelInput>CPF</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <FormInput sx={editar ? editarCss : {}}
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    cpf: e.target.value
                                                });
                                            }}
                                            value={assessorado?.cpf}
                                            disabled={!editar}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container xs={12} md={12}>
                                    <LabelInput>Estado Civil</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <FormInput sx={editar ? editarCss : {}}
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    estadoCivilObs: e.target.value
                                                });
                                            }}
                                            value={assessorado?.estadoCivilObs}
                                            disabled={!editar}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container xs={12} md={7} sx={{ padding: "0px 0px 0px 10px", display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "flex-start" }}>

                                <Grid container xs={6} md={6} sx={{ padding: "0px 10px 0px 0px" }}>
                                    <LabelInput>Data de Nascimento</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DesktopDatePicker
                                                format="DD/MM/YYYY"
                                                sx={editar ? editarCss : { marginTop: "20px !important" }}
                                                onChange={(e: any) => {
                                                    setAssessorado({
                                                        ...assessorado,
                                                        dtNascimento: e
                                                    });
                                                }}
                                                value={assessorado?.dtNascimento ? dayjs(assessorado?.dtNascimento) : null}
                                                disabled={!editar}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid container xs={6} md={6} sx={{ padding: "0px 0px 0px 10px" }}>
                                    <LabelInput>Sexo</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <FormInput sx={editar ? editarCss : {}}
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    sexo: e.target.value
                                                });
                                            }}
                                            value={assessorado?.sexo}
                                            disabled={!editar}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container xs={6} md={6} sx={{ padding: "0px 10px 0px 0px" }}>
                                    <LabelInput>Último Salário</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <NumericFormat
                                            sx={editar ? editarCss : { marginTop: "20px !important" }}
                                            prefix="R$"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            customInput={TextFieldStato}
                                            allowLeadingZeros={false}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    ultimaRemuneracao: e.target.value
                                                });
                                            }}
                                            value={assessorado?.ultimaRemuneracao}
                                            disabled={!editar}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container xs={6} md={6} sx={{ padding: "0px 0px 0px 10px" }}>
                                    <LabelInput>Último Cargo</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <FormInput sx={editar ? editarCss : {}}
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    ultimoCargo: e.target.value
                                                });
                                            }}
                                            value={assessorado?.ultimoCargo}
                                            disabled={!editar}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid container xs={12} md={12} sx={{ padding: "0px 0px 0px 10px", width: "100%" }}>

                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid md={3}></Grid>

                        <Grid container xs={12} md={12} sx={{ padding: "12px 0px 5px 0px", marginTop: "30px" }}>
                            <SubTitulo>
                                Contato
                            </SubTitulo>
                        </Grid>


                        <Grid container xs={12} md={9} sm={12}>
                            <Grid container xs={12} md={5} sx={{ padding: "0px 10px 0px 0px" }}>
                                <Grid container xs={12} md={12}>
                                    <LabelInput>Email</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <FormInput sx={editar ? editarCss : {}}
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    email: e.target.value
                                                });
                                            }}
                                            value={assessorado?.email}
                                            disabled={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container xs={12} md={12}>
                                    <LabelInput>Linkedin</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <FormInput sx={editar ? editarCss : {}}
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    linkedin: e.target.value
                                                });
                                            }}
                                            value={assessorado?.linkedin}
                                            disabled={!editar}
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            http://
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container xs={12} md={7} sx={{ padding: "0px 0px 0px 10px", display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "flex-start" }}>
                               
                                <Grid container xs={12} md={12} sx={{ padding: "0px 0px 0px 10px" }}>
                                    <LabelInput>Email Profissional</LabelInput>
                                    <FormControl required fullWidth variant='outlined'>
                                        <FormInput sx={editar ? editarCss : {}}
                                            onChange={(e: any) => {
                                                setAssessorado({
                                                    ...assessorado,
                                                    emailProfissional: e.target.value
                                                });
                                            }}
                                            value={assessorado?.emailProfissional}
                                            disabled={!editar}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid md={3}></Grid>

                        <Grid container xs={12} md={9} sm={9} sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '30px' }}>
                            {editar &&
                                <ButtonHome
                                    variant="contained"
                                    disabled={loadingSave}
                                    onClick={handleChangeNext}
                                >
                                    {loadingSave ?
                                        <CircularProgress size={20} sx={{
                                            marginRight: "10px",
                                            color: "#b9b9b9"
                                        }} /> :
                                        <img src={SaveIcon} style={{ marginRight: "10px", width: "15px" }} />
                                    }
                                    Salvar
                                </ButtonHome>
                            }
                        </Grid>

                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                            <Divider sx={{ marginTop: '20px' }} orientation="horizontal" />
                        </Grid>

                    </Grid> :
                    <>
                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size={50} sx={{
                                marginRight: "10px"
                            }} />
                        </Grid>
                    </>
                }

            </DivMain>

            <Grid xs={12} md={12} sm={12} sx={{ padding: '0px 20px', opacity: !editar ? "1" : "0.3 !important" }}>
                <EnderecosComponent />

                <TelefonesComponent />

                <FormacaoAcademicaComponent />

                <IdiomasComponent />

                <HistoricoProfissionalComponent />
            </Grid>
        </GridContainer >
    );
};

const ButtonHome = styled(Button)`
  background-color: #118BD7 !important;
  color: white !important;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const DivMain = styled.div`
  background-image: url(${ImgBackground});
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  width: 100%;
  min-height: 80vh;
  padding: 35px 32px 0px;
`;

const DadosPessoaisTitulo = styled(Grid)`
font-family: Roboto Slab !important;
font-size: 64px !important;
font-weight: 300 !important;
line-height: 72px !important;
letter-spacing: -0.03em !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;

background: linear-gradient(90deg, #118BD7 0%, #75B21D 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`

const SubTitulo = styled(Typography)`
font-family: Roboto Slab !important;
font-size: 20px !important;
font-weight: 400 !important;
line-height: 32px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
width: 120px !important;
height: 32px !important;
top: 312px !important;
left: 32px !important;
gap: 0px !important;
color: rgba(8, 70, 124, 1) !important;
`

const TituloEditar = styled(Typography)`
styleName: Text md/Semibold !important;
font-family: Inter !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #0D6EB3 !important;

display: flex !important;
align-items: center !important;

`

const LabelInput = styled(InputLabel)`
//styleName: Text sm/Medium !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
margin-bottom: -15px !important;
margin-top: 20px !important;
`

const NavPage = styled(Grid)`
font-family: Inter !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 20px !important;
letter-spacing: -0.01em !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;

display: flex !important;
align-items: center !important;

padding-bottom: 30px !important;
`



export default DadosPessoais;

