import { empresasAtom } from './EmpresaState';
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useBaseActions } from '../base.actions';
import { useAxiosWrapper } from '../../services/axiosWrapper';
import { authAtom } from '../usuarios/AuthState';
import { history } from '../../helpers/history';

function useEmpresasActions() {
  const baseActions = useBaseActions('empresas', empresasAtom, false);
  const axiosWrapper = useAxiosWrapper();
  const authState = useRecoilValue(authAtom);
  const setAuth = useSetRecoilState(authAtom);

  return {
    list,
    listConfigEmpresa,
    listConfigEmpresaNiveis,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };

  async function list() {
    let url = `${process.env.REACT_APP_API_URL}/v1/assessorados/configEmpresas`;

    try {
      const recursos = await axiosWrapper.get(url);

      return recursos;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }

  }
  
  async function listConfigEmpresa() {
    let url = `${process.env.REACT_APP_API_URL}/v1/assessorados/configContatoEmpresas`;

    try {
      const recursos = await axiosWrapper.get(url + `?contatoId=${authState?.id}`);

      return recursos;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }

  }

  async function listConfigEmpresaNiveis() {
    let url = `${process.env.REACT_APP_API_URL}/v1/assessorados/configNivelPosicoes`;

    try {
      const recursos = await axiosWrapper.get(url);

      return recursos;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }

  }
  
  
}

export { useEmpresasActions };
