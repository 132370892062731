import { Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Acoes from './pages/Acoes';
import ConteudoComponent from './pages/Conteudo';
import DadosPessoais from './pages/DadosPessoais';
import Home from './pages/Home';
import Login from './pages/Login';
import MenuComponent from './pages/Menu';
import Pagina404 from './pages/Pagina404';
import PPC from './pages/PPC';
import Programa from './pages/Programa';
import RedefinicaoSenha from './pages/RedefinirSenha/RedefinicaoSenha';
import SolicitarRedefinicaoSenha from './pages/RedefinirSenha/SolicitarRedefinicaoSenha';
import SecaoComponent from './pages/Secao';

function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/redefinir-senha" component={SolicitarRedefinicaoSenha} />
      <Route exact path="/redefinir-senha/:id" component={RedefinicaoSenha} />

      <Header>
        <Route exact path="/" component={Home} />
        <Route exact path="/minha-transicao" component={Home} />
        <Route exact path="/minha-transicao/acoes" component={Acoes} />
        <Route exact path="/minha-transicao/programa" component={Programa} />
        <Route exact path="/minha-transicao/dados-pessoais" component={DadosPessoais} />
        <Route exact path="/minha-transicao/programa/ppc" component={PPC} />

        {/*  Menus normal  */}
        <Route exact path="/menus/:id" component={MenuComponent} />
        <Route exact path="/menus/:menuId/secoes/:id" component={SecaoComponent} />
        <Route
          exact
          path="/menus/:menuId/secoes/:secaoId/conteudos/:id"
          component={ConteudoComponent}
        />

        {/*  Menus com submenu dentro  */}
        <Route exact path="/menus/:menuIdParent/menus/:id" component={MenuComponent} />
        <Route
          exact
          path="/menus/:menuIdParent/menus/:menuId/secoes/:id"
          component={SecaoComponent}
        />
        <Route
          exact
          path="/menus/:menuIdParent/menus/:menuId/secoes/:secaoId/conteudos/:id"
          component={ConteudoComponent}
        />
        <Route exact path="/conteudos/:id" component={ConteudoComponent} />
      </Header>
      <Route exact component={Pagina404} />
    </Switch>
  );
}

export default Routes;
