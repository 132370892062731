import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  FilledInput,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormControlStato, GridContainer, GridItem } from '../../commons/styleds/styleds';
import { history } from '../../helpers/history';
import { useRedefinirSenhaActions } from '../../states/redefinirSenha/redefinirSenha.actions';
import Logo from './../../assets/STATO-INTOO-LOGO-MY-INTOO-VERS-1.png';
import { TitleLogin } from './SolicitarRedefinicaoSenha';

export interface IParams {
  id: string;
}

const RedefinicaoSenha: React.FC = () => {
  const { id } = useParams<IParams>();
  const redefinirSenhaActions = useRedefinirSenhaActions();
  const [senha, setSenha] = useState<string>('');
  const [mostrarSenha, setMostrarSenha] = useState<boolean>(false);
  const [confirmarSenha, setConfirmarSenha] = useState<string>('');
  const [mostrarConfirmarSenha, setMostrarConfirmarSenha] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<string | null>(null);

  const validarSenhasIguais = () => senha === confirmarSenha;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErro(null);

    if (!validarSenhasIguais()) {
      setErro('As senhas não são iguais.');
      return;
    }

    if (senha.length < 8) {
      setErro('A senha deve ter pelo menos 8 caracteres.');
      return;
    }

    setLoading(true);

    redefinirSenhaActions
      .update(Number(id), { senha, redefinicaoId: id })
      .then((resp: any) => {
        toast.success('Enviado com sucesso.');
        setLoading(false);
        history.push('/login');
      })
      .catch((error: any) => {
        toast.error('Erro ao redefinir senha.');
        setLoading(false);
      });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <GridContainer container sx={{ height: '100vh !important' }}>
        <GridItem item xs={12} md={6}>
          <img src={Logo} alt="logo" width={'60%'} />
        </GridItem>
        <GridItem item xs={12} md={6} color={'var(--blue2)'}>
          <Grid item xs={10} md={9}>
            <form onSubmit={onSubmit}>
              <GridItem item xs={12} md={12} color={'var(--blue2)'}>
                <TitleLogin>Redefinição de senha</TitleLogin>

                <FormControlStato sx={{ width: '100%' }} variant="filled">
                  <InputLabel htmlFor="senha">Nova Senha</InputLabel>
                  <FilledInput
                    id="senha"
                    type={mostrarSenha ? 'text' : 'password'}
                    value={senha}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSenha(event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setMostrarSenha(!mostrarSenha)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControlStato>

                <FormControlStato sx={{ width: '100%', marginTop: '15px' }} variant="filled">
                  <InputLabel htmlFor="confirmarSenha">Confirmar Senha</InputLabel>
                  <FilledInput
                    id="confirmarSenha"
                    type={mostrarConfirmarSenha ? 'text' : 'password'}
                    value={confirmarSenha}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setConfirmarSenha(event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setMostrarConfirmarSenha(!mostrarConfirmarSenha)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {mostrarConfirmarSenha ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControlStato>

                {erro && (
                  <Typography variant="body2" color="error">
                    {erro}
                  </Typography>
                )}
              </GridItem>
              <GridItem item xs={12} md={12} color={'var(--blue2)'} style={{ paddingTop: '15px' }}>
                <Grid style={{ textAlign: 'right', width: '100%' }}>
                  <Button
                    variant="outlined"
                    style={{ marginRight: '15px' }}
                    onClick={() => history.goBack()}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" variant="contained" disabled={loading}>
                    {loading ? 'Enviando...' : 'Enviar'}
                  </Button>
                </Grid>
              </GridItem>
            </form>
          </Grid>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default RedefinicaoSenha;
