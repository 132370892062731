import { Box, Button, FormControl, Grid, Icon, Select, TextField } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import styled from 'styled-components';

export const GridContainer = styled(Grid)`
  margin: 0px !important;
  height: 100% !important;
  width: 100%;
`;

export const GridItem = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  background-color: white;
  @media (min-width: 900px) {
    background-color: ${(props: any) => props.color};
  }
`;

export const FormControlStato = styled(FormControl)`
  background-color: white;
  border-radius: 4px;
`;

export const GenericText = styled.p`
  font-family: Arial;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
`;

export const SpanBold = styled.span`
  font-weight: bold;
`;

const GradientTitle = styled.h2`
  font-family: "Arial Narrow Bold", Arial;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 500;
`;

export const GradientBlueTitle = styled(GradientTitle)`
  background-image: linear-gradient(
    90deg,
    var(--blue2),
    var(--blue3),
    var(--blue2)
  );
`;

export const GradientGreenTitle = styled(GradientTitle)`
  background-image: linear-gradient(90deg, var(--green2), var(--green3));
`;

export const Title = styled.h3`
  font-family: "Arial Narrow Bold";
  font-size: 26px;
  font-weight: 500;
`;

export const TitleBlue = styled(Title)`
  color: var(--blue3);
`;

export const TitleGreen = styled(Title)`
  color: var(--green3);
`;

export const GridContainerCenter = styled(GridContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 100%;
`;

export const LogoGridItem = styled(GridItem)`
  background: transparent;
`;

export const ButtonStato = styled(Button)`
  border-radius: 17px !important;
`;

export const GridItemLeft = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const GridItemTopPage = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--black);
`;

export const GridTopPage = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--black);
  height: 85px;
  max-height: 85px;
  min-height: 60px;
  @media (max-width: 900px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const GridTopPageHome = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;;
  align-items: center;
  color: var(--black);
  height: 85px;
  max-height: 85px;
  min-height: 60px;
  @media (max-width: 900px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const GenericBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TextFieldStato = styled(TextField)`
  fieldset {
    border-radius: 17px;
  }
`;

export const SelectStato = styled(Select)`
  fieldset {
    border-radius: 17px;
  }
`;

export const IconTituloStato = styled(Icon)`
  font-size: 30px;
  border: 2px solid black;
  border-radius: 25px;
  padding: 5px;
  margin-left: 30px;
  margin-right: 10px;
`;

export const IconArrowBackStato = styled(Icon)`
  font-size: 30px !important;
  cursor: pointer;
  border-radius: 25px;
  padding: 5px;
  margin-left: 30px;
`;

export const IconStato = styled(Icon)`
  font-size: 70px !important;
  color: var(--blue3);
`;

export const IconDrawer = styled(Icon)`
    margin-top: 5px;
    font-size: 26px !important;
    cursor: pointer;
`;

export const PhoneInputStato = styled(MuiTelInput)`
  fieldset {
    border-radius: 17px !important;
  }
`;

