import { useBaseActions } from '../base.actions';

function useAnexosActions() {
  const baseActions = useBaseActions('Anexos', undefined);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useAnexosActions };
