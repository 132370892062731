import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { IMenuItens } from '.';
import { history } from '../../helpers/history';
import { authAtom } from '../../states/usuarios/AuthState';

interface MenuHorizontalProps {
  menuItens: IMenuItens[];
}

const LinkMenu = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 16px;
  display: flex;
  color: #000;
  transition: 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const LiMenu = styled.li``;

const renderMenuItens = (menuItens: IMenuItens[], isFirstLevel = true) => {
  return menuItens.map((menu, index) => (
    <LiMenu key={index} style={{ position: 'relative' }}>
      <LinkMenu
        to={menu.link}
        onMouseEnter={(e) => {
          const subMenu = e.currentTarget.nextElementSibling as any;
          if (subMenu) subMenu.style.display = 'block';
        }}
        onMouseLeave={(e) => {
          const subMenu = e.currentTarget.nextElementSibling as any;
          if (subMenu) subMenu.style.display = 'none';
        }}
      >
        {menu.nome}
        {!isFirstLevel && menu.filhos && menu.filhos.length > 0 && (
          <ArrowRightIcon style={{ marginLeft: '8px' }} />
        )}
      </LinkMenu>
      {menu.filhos && menu.filhos.length > 0 && (
        <Box
          component="ul"
          sx={{
            display: 'none',
            position: 'absolute',
            top: isFirstLevel ? '100%' : 0,
            left: isFirstLevel ? 0 : '100%',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            backgroundColor: '#fff',
            boxShadow: 1,
            zIndex: 1,
            whiteSpace: 'nowrap',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.display = 'block';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.display = 'none';
          }}
        >
          {renderMenuItens(menu.filhos, false)}
        </Box>
      )}
    </LiMenu>
  ));
};

const MenuHorizontal: React.FC<MenuHorizontalProps> = ({ menuItens }) => {
  const setAuth = useSetRecoilState(authAtom);

  const handleCloseSair = () => {
    localStorage.removeItem('usuario');
    setAuth(null);
    history.push('/login');
  };

  return (
    <nav>
      <Box
        component="ul"
        sx={{
          display: 'flex',
          listStyle: 'none',
          padding: 0,
          margin: 0,
          gap: '16px',
        }}
      >
        {renderMenuItens(menuItens)}

        <IconButton color="inherit">
          <SearchIcon />
        </IconButton>
        <IconButton color="inherit" onClick={() => handleCloseSair()}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </nav>
  );
};

export default MenuHorizontal;
