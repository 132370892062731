import { Button, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FormControlStato, GridContainer } from '../../commons/styleds/styleds';
import { DividerMenu } from '../../components/Header';
import { GridItem } from '../../components/Menu';
import { history } from '../../helpers/history';
import { useRedefinirSenhaActions } from '../../states/redefinirSenha/redefinirSenha.actions';
import { DivBackground, TextFieldLogin, TextoBemVindo, Wrapper } from '../Login';
import Logo from './../../assets/STATO-INTOO-LOGO-MY-INTOO-VERS-2 A.png';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Footer from '../../components/Footer';
import { text } from 'node:stream/consumers';
import { Link } from 'react-router-dom';

const SolicitarRedefinicaoSenha: React.FC = () => {
  const redefinirSenhaActions = useRedefinirSenhaActions();
  const [email, setEmail] = useState<string>('');
  const [enviado, setEnviado] = useState<boolean>(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    redefinirSenhaActions.create({ email, origem: 'ASSESSORADO' }).then((resp: any) => {
      toast.success('Enviado com sucesso.');
      setEnviado(true);
    });
  };

  return (
    <>
      <DividerMenu />
      <DivBackground>
        <Wrapper>
          <PaperRedefinirSenha>
            <img src={Logo} alt="logo" style={{ width: 'auto', height: '80px' }} />

            {enviado ? (
              <>
                <Typography variant="h5">Mensagem enviada!</Typography>
                <Link to="/login" style={{ marginTop: '20px' }}>
                  Voltar para o início
                </Link>
              </>
            ) : (
              <>
                <Typography variant="h5">Recuperação de senha</Typography>

                <Typography variant="body2" sx={{ textAlign: 'center', marginY: '20px' }}>
                  Para recuperar sua senha, informe o e-mail cadastrado e siga asinstruções que
                  serão enviadas.
                </Typography>

                <form onSubmit={onSubmit} style={{ marginTop: '20px' }}>
                  <FormControlStato sx={{ width: '100%', marginBottom: '12px' }} variant="filled">
                    <TextFieldLogin
                      id="filled-basic"
                      type="email"
                      placeholder="E-mail"
                      required
                      variant="outlined"
                      value={email}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(event.target.value);
                      }}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailOutlinedIcon />
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </FormControlStato>
                  <Button type="submit" variant="contained" fullWidth sx={{ marginTop: '32px' }}>
                    Enviar
                  </Button>
                </form>
              </>
            )}
          </PaperRedefinirSenha>
        </Wrapper>
      </DivBackground>
      <Footer />
    </>
  );
};

export const PaperRedefinirSenha = styled(Paper)`
  width: 400px;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 576px) {
    width: 312px;
  }
`;

export const TitleLogin = styled(Paper)`
  width: 400px;
`;

export default SolicitarRedefinicaoSenha;
