import { useBaseOneActions } from '../base.actionsOne';

function useCurriculosActions() {
  const baseActions = useBaseOneActions('Curriculos', undefined);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useCurriculosActions };
