import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from 'styled-components';
import { Grid } from '@mui/material';
import Atencao from '../../assets/iconsUX/alert-triangle.png';

interface IAlert {
    handleClose: () => void;
    handleSucess: () => void;
    labelTitle: string;
    labelDescription: string;
    labelBtnClose: string;
    labelBtnSucess: string;
    open: boolean;
}

const AlertComponent: React.FC<IAlert> = ({
    handleClose,
    handleSucess,
    labelTitle,
    labelDescription,
    labelBtnClose,
    labelBtnSucess,
    open }) => {


    return (
        <>
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: { borderRadius: 8 }
                    }}
                >
                    <DialogAlert>
                        <Grid sx={{ paddingLeft: "20px" }}>
                            <GridIcon>
                                <img src={Atencao} style={{ width: "25px", height: "25px" }} />
                            </GridIcon>
                        </Grid>
                        <DialogTitleDailog id="alert-dialog-title">
                            {labelTitle}
                        </DialogTitleDailog>
                        <DialogContentDailog>
                            <DialogContentText id="alert-dialog-description">
                                {labelDescription}
                            </DialogContentText>
                        </DialogContentDailog>
                        <DialogActions sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <ButtonAlertClose variant="outlined" onClick={handleClose}>
                                {labelBtnClose}
                            </ButtonAlertClose>
                            <ButtonAlert variant="contained" onClick={handleSucess} autoFocus>
                                {labelBtnSucess}
                            </ButtonAlert>
                        </DialogActions>
                    </DialogAlert>
                </Dialog>
            </React.Fragment>
        </>
    );
};

const ButtonAlertClose = styled(Button)`
width: 170px !important;
height: 44px !important;

font-family: Inter !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;

color: #414651 !important;
border: 1px solid #D5D7DA !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

border-radius: 8px !important;
margin-right: 10px !important;
`;

const ButtonAlert = styled(Button)`
width: 170px !important;
height: 44px !important;
padding: 10px 18px 10px 18px !important;
gap: 8px !important;
border-radius: 8px !important;
border: 1px 0px 0px 0px !important;
opacity: 0px !important;

background: #118BD7 !important;
border: 1px solid #118BD7 !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

`;

const DialogAlert = styled(Grid)`
width: 450px !important;
padding: 20px !important;
`;

const DialogTitleDailog = styled(DialogTitle)`
//styleName: Text lg/Semibold !important;
font-family: Inter !important;
font-size: 18px !important;
font-weight: 600 !important;
line-height: 28px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #181D27 !important;
font-size: 22px !important;

`;

const DialogContentDailog = styled(DialogContent)`
//styleName: Text sm/Regular !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #535862 !important;
`;

const GridIcon = styled(Grid)`
width: 60px !important;
height: 60px !important;
gap: 0px !important;
border-radius: 28px !important;
border: 8px 0px 0px 0px !important;
opacity: 0px !important;
background: #FEF0C7 !important;
border: 8px solid #FFFAEB !important;
Size="lg" !important;
Color="Warning" !important;
Theme="Light circle outline" !important;

display: flex !important;
justify-content: center !important;
align-items: center !important;
`;


export default AlertComponent;
