import { useBaseOneActions } from '../base.actionsOne';

function useRedefinirSenhaActions() {
  const baseActions = useBaseOneActions('redefinicaoSenha');

  return {
    create: baseActions.create,
    update: baseActions.update,
    get: baseActions.get,
  };
}

export { useRedefinirSenhaActions };
