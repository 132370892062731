import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { styled } from 'styled-components';
import React from 'react';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer } from '../../commons/styleds/styleds';
import Box from '@mui/material/Box';
import { Link, useLocation, useParams } from 'react-router-dom';
import { history } from '../../helpers/history';
import { useMenusActions } from '../../states/menus/menu.actions';
import { IArea, IConteudo, IMenu, ISecao } from '../../commons/types/base';
import { Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import type { IMenuItens } from '../../components/Header';
import { findBreadcrumb } from '../../components/Breadcrumb/findBreadcrumb';
import Breadcrumb from '../../components/Breadcrumb';
import Eventos from '../Eventos';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function createHTMLFromString(htmlString: any) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

interface IParams {
  id?: string;
}

const MenuComponent: React.FC = () => {
  const path = window.location.pathname;
  const { id } = useParams<IParams>();
  const [area, setArea] = useState<IArea>();
  const [secoes, setSecoes] = useState<ISecao[]>([]);
  const location = useLocation();
  const currentPath = location.pathname;
  const [isEvento, setIsEvento] = useState<boolean>(false);

  const menusActions = useMenusActions();

  useEffect(() => {
    const loadData = async () => {
      let structure = JSON.parse(localStorage.getItem('menusDinamicos') ?? '[]') as IMenuItens[];
      const breadcrumb = findBreadcrumb(currentPath, structure);
      const indexEventos = breadcrumb!.findIndex((f: any) => f.nome === 'Eventos');
      if (indexEventos >= 0 && indexEventos < breadcrumb!.length - 1) {
        setIsEvento(true);
      }
    }
    loadData();
    menusActions
      .get()
      .then((d) => {
        let menus: IMenu = d.resultado;

        let area = menus.areas.find((f) => f.id == Number(id));
        let secoes = menus.secoes.filter((f) => f.idArea == Number(id));
        setArea(area);
        setSecoes(secoes);
      })
      .catch((erro) => {
      });
  }, [id]);

  return (
    <div>
      {isEvento 
        ? <Eventos />
        : <GridContainer
            container
            direction="row"
            spacing={0}
            sx={{
              display: 'flex',
              padding: '10px 15px 10px 10px',
              margin: '0px 30px !important',
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={12}>
              <Breadcrumb />
            </Grid>

            <Grid
              container
              direction="row"
              spacing={0}
              sx={{ paddingTop: '25px', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}
              xs={12}
              md={12}
            >
              <Grid
                container
                xs={12}
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                }}
              >
                <Grid xs={12} md={12} sm={12} sx={{ borderBottom: '2.5px solid rgb(123 179 53)' }}>
                  <Typography sx={{ padding: '10px 0px' }} variant="h4" component="h2">
                    {area?.nome}
                  </Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  md={12}
                  sx={{ paddingBottom: '10px', display: 'flex', borderBottom: 'solid 1.5px #dfdfdf' }}
                >
                  <Typography sx={{ padding: '10px' }} variant="caption">
                    {createHTMLFromString(area?.descricao)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container xs={12} md={12} sx={{ padding: '50px 10px', display: 'flex' }}>
                {secoes.map((item: ISecao) => {
                  return (
                    <>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        sx={{
                          padding: '10px 10px',
                          display: 'flex',
                          borderBottom: 'solid 1.5px #dfdfdf',
                        }}
                      >
                        <Link to={path + '/secoes/' + item.id}>
                          <IconButton>
                            <ArrowForwardIosIcon
                              sx={{
                                fontSize: '16px',
                                color: '#551a8b',
                              }}
                            />
                          </IconButton>
                          {item.nome}
                        </Link>

                        <Grid
                          container
                          xs={12}
                          md={12}
                          sx={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '30px' }}
                        >
                          {createHTMLFromString(item.descricao)}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </GridContainer>
      }
    </div>
  );
};

export default MenuComponent;
