import { useBaseActions } from '../base.actions';
import { telefonesAtom } from './TelefoneState';

function useTelefonesActions() {
  const baseActions = useBaseActions('telefones', telefonesAtom, false);
 
  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useTelefonesActions };
