import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Typography } from '@mui/material';
import { styled } from 'styled-components';
import UpChartImg from '../../../assets/up_chart.png';
// import DownChartImg from '../../../assets/down-chart.png';

interface DashboardCardProps {
  title: string;
  value?: number;
  percentageChange: number;
  positive?: boolean;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, value, percentageChange }) => {
  return (
    <CardContainer>
      <CardBackgroundImage>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h4" sx={{ marginTop: '8px' }}>
          {value ?? '-'}
        </Typography>
        <PercentageChange>
          {percentageChange >= 0 ? (
            <ArrowUpwardIcon color="success" />
          ) : (
            <ArrowDownwardIcon color="error" />
          )}
          <SpanPercentage
            positive={percentageChange >= 0}
          >{`${percentageChange.toString().replace('-', '')}% `}</SpanPercentage>{' '}
          {`${percentageChange >= 0 ? ' a mais' : ' a menos'} no mês`}
        </PercentageChange>
      </CardBackgroundImage>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e9eaeb;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  flex: 1 1 calc(33% - 16px);
  min-width: 384px;
  height: 176px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SpanPercentage = styled.span<{ positive?: boolean }>`
  color: ${(props) => (props.positive ? 'green' : 'red')} !important;
`;

const PercentageChange = styled(Typography)`
  margin-top: 8px;
  display: flex;
`;

const CardBackgroundImage = styled.div`
  background-image: url(${UpChartImg});
  background-repeat: no-repeat;
  background-position: bottom right;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export default DashboardCard;
