
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel } from '@mui/material';
import { ITelefone } from '../../../commons/types/usuatios';
import { useTelefonesActions } from '../../../states/telefones/telefone.actions';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/usuarios/AuthState';
import { TextFieldStato } from '../../../commons/styleds/styleds';
import TableCrud from '../../../components/TableCrud/TableCrud';
import AlertComponent from '../../../components/Alert/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FeaturedIcon from '../../../assets/iconsUX/Featured icon.png';
import { styled } from 'styled-components';

const empty: ITelefone = {
    id: 0,
    idContato: 0,
    idTipo: 3,
    nome: '',
    ddd: '',
    telefone: '',
    ramal: '',
    principal: false,
    ativo: true,
};

const TelefonesComponent: React.FC = () => {
    const telefoneActions = useTelefonesActions();
    const [telefones, setTelefones] = useState<ITelefone[]>([]);

    const auth = useRecoilValue(authAtom);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingGet, setLoadingGet] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [telefone, setTelefone] = useState<ITelefone>(empty);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [idTemp, setIdTemp] = useState<number>(0);

    useEffect(() => {
        setLoadingGet(true);
        telefoneActions.list(false, `?contatoId=${auth?.id}`)
            .then((resp: any) => {
                setTelefones(resp)
                setLoadingGet(false);
            })
            .catch((err: any) => {
                setLoadingGet(false);
                toast.warn(err)
            });
    }, []);


    const openDrawerTelefone = (result: any) => {
        setTelefone(empty);
        setOpen(result);
    };

    const handleEditar = (item: ITelefone) => {
        setTelefone(item);
        setOpen(true);

    };

    const handleApagar = (id: any) => {
        setIdTemp(id);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setIdTemp(0);
        setTelefone(empty);
    };

    const handleSucess = () => {
        setOpenDialog(false);

        telefoneActions.delete(idTemp).then(() => {
            const sub = telefones.filter((f) => f.id !== idTemp);
            setTelefones(sub);
            toast.success('Apagado com sucesso!');
        });
    };

    const AdicionarNaLista = async () => {
        setLoadingSave(true);

        try {
            telefone.idTipo = 2;

            if ((!telefone?.idContato || telefone?.idContato <= 0) && Number(auth?.id) > 0) {
                telefone.idContato = Number(auth?.id);
            }

            let response: ITelefone;
            if (telefone.id && telefone.id > 0) {
                response = await telefoneActions.update(telefone.id, telefone);
                setTelefones((prevTelefones) => {
                    const newList = prevTelefones.filter((f) => f.id !== response.id);
                    return newList.concat(response);
                });

                toast.success('Telefone atualizado');
            } else {
                response = await telefoneActions.create(telefone);
                setTelefones((prevTelefones) => prevTelefones.concat(response));

                toast.success('Telefone salvo');
            }

            setTelefone(emptyTelefone());
            openDrawerTelefone(false);
        } catch (error) {
            console.error(error);
            toast.error('Erro ao salvar telefone');
        } finally {
            setLoadingSave(false);
        }
    };

    const emptyTelefone = () => {
        return {
            id: 0,
            idContato: Number(auth?.id),
            idTipo: 3,
            nome: '',
            ddd: '',
            telefone: '',
            ramal: '',
            principal: false,
            ativo: true,
        }
    }

    const handleTelefone = (event: any) => {
        if (event.target.value.length > 10) return '';
        event.target.value = event.target.value.replace(/\D/g, '');
        event.target.value = event.target.value.replace(/(\d)(\d{4})$/, '$1-$2');
        setTelefone({ ...telefone, telefone: event.target.value });
    };

    const handleDDD = (event: any) => {
        if (event.target.value.length > 5) return '';
        event.target.value = event.target.value.replace(/\D/g, '');
        event.target.value = event.target.value.replace(/(\d{2})(\d)/, '($1)');
        setTelefone({ ...telefone, ddd: event.target.value });
    };

    return (
        <>
            <Grid xs={12} md={12} sx={{ padding: "40px 0px 0px 0px" }}>
                <TableCrud
                    handleClickDelete={handleApagar}
                    handleClickEdit={handleEditar}
                    handleClickAdd={openDrawerTelefone}
                    labelTitle="Telefones"
                    labelAddButton='Telefone'
                    rows={telefones}
                    columns={[
                        "nome",
                        "ddd",
                        "telefone",
                        "ramal",
                        "principal",
                    ]}
                    columnsLabels={[
                        "Apelido",
                        "DDD",
                        "Numero",
                        "Ramal",
                        "Principal",
                    ]}
                    loadingGet={loadingGet}
                />

            </Grid>

            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={() => openDrawerTelefone(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: { borderRadius: 8 }
                    }}
                >
                    <DialogAlert>
                        <Grid sx={{ paddingLeft: "20px" }}>
                            <GridIcon>
                                <img src={FeaturedIcon} />
                            </GridIcon>
                        </Grid>
                        <DialogTitleDailog id="alert-dialog-title">
                            {telefone?.id && telefone?.id > 0 ? 'Alteração de ' : 'Adicionar '} Telefone
                        </DialogTitleDailog>
                        <DialogContentDailog>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', marginTop: '-10px', marginBottom: '20px' }}>
                                    <Grid container xs={7} sm={7} md={7} sx={{ display: 'flex', paddingRight: '5px' }}>
                                        <Grid xs={12} md={12}>
                                            <LabelInput>Apelido</LabelInput>
                                            <FormControl
                                                id='apelido'
                                                required
                                                fullWidth
                                                variant='outlined'
                                                sx={{ marginTop: '20px' }}
                                            >
                                                <TextFieldStato
                                                    type='text'
                                                    value={telefone?.nome}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setTelefone({ ...telefone, nome: event.target.value });
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', paddingLeft: '5px' }}>
                                        <Grid xs={3} md={3} sx={{ paddingRight: '5px' }}>
                                            <LabelInput>DDD</LabelInput>
                                            <FormControl
                                                required
                                                fullWidth
                                                variant='outlined'
                                                sx={{ marginTop: '20px' }}
                                            >
                                                <TextFieldStato
                                                    id={'TelefoneInput'}
                                                    type='text'
                                                    value={telefone?.ddd}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleDDD(event);
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} md={6} sx={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                            <LabelInput>Número</LabelInput>
                                            <FormControl
                                                required
                                                fullWidth
                                                variant='outlined'
                                                sx={{ marginTop: '20px' }}
                                            >
                                                <TextFieldStato
                                                    id={'TelefoneInput'}
                                                    type='numero'
                                                    value={telefone?.telefone}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleTelefone(event);
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={3} md={3} sx={{ paddingLeft: '5px' }}>
                                            <LabelInput>Ramal</LabelInput>
                                            <FormControl
                                                required
                                                fullWidth
                                                variant='outlined'
                                                sx={{ marginTop: '20px' }}
                                            >
                                                <TextFieldStato
                                                    id={'TelefoneInput'}
                                                    type='text'
                                                    value={telefone?.ramal}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setTelefone({ ...telefone, ramal: event.target.value });
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: '5px' }}>
                                        <FormControlLabel
                                            required
                                            sx={{ marginTop: '20px' }}
                                            label="Principal"
                                            control={
                                                <Checkbox onClick={() => setTelefone({ ...telefone, principal: !telefone.principal })} />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContentDailog>
                        <DialogActions sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <ButtonAlertClose variant="outlined" onClick={() => openDrawerTelefone(false)}>
                                Cancelar
                            </ButtonAlertClose>
                            <ButtonAlert variant="contained" onClick={AdicionarNaLista} autoFocus>
                                Salvar
                            </ButtonAlert>

                        </DialogActions>
                    </DialogAlert>
                </Dialog>
            </React.Fragment>

            <AlertComponent
                open={openDialog}
                labelTitle="Deseja excluir este telefone?"
                labelDescription="O telefone será removido permanentemente dos seus dados cadastrados"
                labelBtnClose="Cancelar"
                labelBtnSucess="Excluir"
                handleClose={handleClose}
                handleSucess={handleSucess}
            />
        </>
    );
};

const ButtonAlertClose = styled(Button)`
width: 170px !important;
height: 44px !important;

font-family: Inter !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;

color: #414651 !important;
border: 1px solid #D5D7DA !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

border-radius: 8px !important;
margin-right: 10px !important;
`;

const ButtonAlert = styled(Button)`
width: 170px !important;
height: 44px !important;
padding: 10px 18px 10px 18px !important;
gap: 8px !important;
border-radius: 8px !important;
border: 1px 0px 0px 0px !important;
opacity: 0px !important;

background: #118BD7 !important;
border: 1px solid #118BD7 !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

`;

const DialogAlert = styled(Grid)`
width: 480px !important;
padding: 20px !important;
`;

const DialogTitleDailog = styled(DialogTitle)`
//styleName: Text lg/Semibold !important;
font-family: Inter !important;
font-size: 18px !important;
font-weight: 600 !important;
line-height: 28px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #181D27 !important;
font-size: 22px !important;

`;

const DialogContentDailog = styled(DialogContent)`
//styleName: Text sm/Regular !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #535862 !important;
`;

const GridIcon = styled(Grid)`
width: 60px !important;
height: 60px !important;
gap: 0px !important;
border-radius: 28px !important;
border: 8px 0px 0px 0px !important;
opacity: 0px !important;
Size="lg" !important;
Color="Warning" !important;
Theme="Light circle outline" !important;

display: flex !important;
justify-content: center !important;
align-items: center !important;
`;

const LabelInput = styled(InputLabel)`
//styleName: Text sm/Medium !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
margin-bottom: -15px !important;
margin-top: 20px !important;
`;

export default TelefonesComponent;
