import { FormControl, Grid, InputLabel, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from 'styled-components';
import PerfilRealizacoes from '../PerfilRealizacoes';

interface Props {
  novoUsuario: boolean;
}

const PPCStepper3: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  return (
    <>
      <Grid container xs={12} md={12} sm={12} sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}>
        <Grid xs={12} md={12} sm={12}>
          <Typography sx={{ padding: "10px" }} variant="h4" component="h2">
            Resultados, Realizações e Competências
          </Typography>
        </Grid>
        <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
          <Typography sx={{ padding: "10px 0px" }} variant="caption">
            Ao analisar sua trajetória profissional, você certamente lembrará de suas realizações, suas conquistas, seus troféus. Poderá ter sido uma ocasião em que você solucionou um conflito, elaborou um plano de trabalho, aproveitou uma oportunidade, liderou ou implementou um projeto, liderou uma equipe, desenvolveu um novo produto, estabeleceu e implementou novos KPI’s, etc. Todas essas realizações certamente geraram resultados para a sua empresa. Como início da sua preparação para sua transição de carreira, você fará um levantamento minucioso destas realizações. Não será um trabalho fácil, mas permitirá que você compreenda melhor suas áreas de especialidade e obtenha um perfil estruturado e claro de você. Os benefícios deste exercício são:
          </Typography>

          <Grid>
            <ul>
              <li style={{ fontSize: "14px", paddingBottom: "10px" }}>
                Obter dados para a definição do seu próximo passo profissional.
              </li>
              <li style={{ fontSize: "14px", paddingBottom: "10px" }}>
                Encontrar a “matéria prima” para elaborar ou melhorar o seu currículo.
              </li>
              <li style={{ fontSize: "14px", paddingBottom: "10px" }}>
                Preparar a base para identificar suas habilidades e competências.
              </li>
              <li style={{ fontSize: "14px", paddingBottom: "10px" }}>
                Estruturar-se para contar e descrever a sua história numa entrevista.
              </li>
            </ul>
          </Grid>

          <Grid>
            <Paragrafo>
              Faça o trabalho seguindo o modelo abaixo, descrevendo:
            </Paragrafo>
            <Paragrafo>
              <b>O PROBLEMA-DESAFIO: </b>
              (Descreva qual era o cenário e o desafio que enfrentou). Trata-se de uma OPORTUNIDADE ou um PROBLEMA que vivenciou. Explore bem!
            </Paragrafo>
            <Paragrafo>
              <b>A AÇÃO OU AÇÕES: </b>
              (Como enfrentou este desafio? Qual foi o seu papel e o que você fez?). Descreva o passo a passo das ações que você realizou diante do cenário apresentado acima, quais os obstáculos que você enfrentou e quais os recursos que utilizou. Escreva em 1a. pessoa.
            </Paragrafo>
            <Paragrafo>
              <b>O RESULTADO: </b>
              (Qual resultado obteve com este desafio?). Pense nos resultados QUANTITATIVOS ou QUALITATIVOS obtidos (números, pessoas, percentuais, economias, melhorias, quantidades, dimensões e ganhos). Ou seja, considere os benefícios que gerou para a EMPRESA e/ou ÁREA em que atuava.
            </Paragrafo>
            <Paragrafo>
              <b>SÍNTESE: </b>
              Reúna o que descreveu nesses dois tópicos (desafio + resultados obtidos). Elabore estas frases e reescreva num ÚNICO ‘bullet point”: será útil no seu CV.
            </Paragrafo>
            <Paragrafo>
              <b>COMPETÊNCIAS: </b>
              (Quais competências você entende que utilizou para vencer esse desafio e/ou desenvolveu A PARTIR desse desafio).
            </Paragrafo>

            <Paragrafo><b>Exemplo:</b></Paragrafo>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableBody>
                  <TableRow>
                    <TableCell width={"200px"}>
                      <b>PROBLEMA - DESAFIO</b>
                    </TableCell>
                    <TableCell>
                      Transformar a marca XXXXXX no motor de crescimento da divisão na América Latina
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>AÇÃO</b>
                    </TableCell>
                    <TableCell>
                      Desenvolvi a estratégia de expansão regional, priorizando países e marcas a serem expandidas. Adaptei o mix produto e comunicação para melhor adequar a realidade dos países. Revisei a estratégia de sourcing regional de maneira a reduzir custos e viabilizar economicamente a expansão da marca.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>RESULTADOS</b>
                    </TableCell>
                    <TableCell>
                      45% de crescimento de vendas no primeiro ano e plano de expansão regional com potencial de dobrar as vendas na região em 3 anos
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>SÍNTESE</b>
                    </TableCell>
                    <TableCell>
                      Liderei o desenvolvimento de estratégia comercial e de marketing na América Latina, adaptando regionalmente o mix do produto e a comunicação, o que trouxe como resultado o crescimento de 18% no volume de vendas no primeiro ano.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>COMPETÊNCIA</b>
                    </TableCell>
                    <TableCell>
                      Liderança / Visão Estratégica / Comunicação
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Paragrafo>
              Considere fazer 3 ou mais realizações/resultados por cargo para que o CV fique equilibrado.
            </Paragrafo>
          </Grid>
        </Grid>

        <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
          <PerfilRealizacoes novoUsuario={false}></PerfilRealizacoes>
        </Grid>

      </Grid>
    </>
  );
};

const Paragrafo = styled(Typography)`
  font-size: 14px !important;
  padding-bottom: 10px;
`;

export default PPCStepper3;
