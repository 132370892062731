import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
} from '@mui/material';
import { useEnderecosActions } from '../../../states/enderecos/endereco.actions';
import { IEndereco } from '../../../commons/types/usuatios';
import { toast } from 'react-toastify';
import { authAtom } from '../../../states/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { TextFieldStato } from '../../../commons/styleds/styleds';
import { styled } from 'styled-components';
import TableCrud from '../../../components/TableCrud/TableCrud';
import AlertComponent from '../../../components/Alert/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FeaturedIcon from '../../../assets/iconsUX/Featured icon.png';

const empty: IEndereco = {
  id: 0,
  idContato: 0,
  idTipo: 3,
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  pais: '',
  principal: false,
  ativo: true,
};

const EnderecosComponent: React.FC = () => {
  const enderecoActions = useEnderecosActions();
  const [enderecos, setEnderecos] = useState<IEndereco[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [idTemp, setIdTemp] = useState<number>(0);

  const [endereco, setEndereco] = useState<IEndereco>(empty);

  const auth = useRecoilValue(authAtom);

  const [cepValido, setCepValido] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingGet, setLoadingGet] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [loadingCep, setLoadingCep] = useState<boolean>(false);

  useEffect(() => {
    enderecoActions
      .list(false, `?contatoId=${auth?.id}`)
      .then((resp: any) => {
        setLoadingGet(false);
        setEnderecos(resp);
      })
      .catch((err: any) => {
        toast.warn(err);
        setLoadingGet(false);
      });
  }, []);

  const handleEditar = (endereco: IEndereco) => {
    setEndereco(endereco);
    setOpen(true);
  };

  const openDrawerEndereco = (result: any) => {
    setEndereco(empty);
    setOpen(result);
  };

  const getUrlViaCep = (cep: string) => {
    const Httpreq = new XMLHttpRequest();
    Httpreq.open('GET', 'https://viacep.com.br/ws/' + cep.replace('-', '') + '/json/', false);
    Httpreq.send(null);
    return Httpreq.responseText;
  };

  const handleApagar = (id: any) => {
    setIdTemp(id);
    setOpenDialog(true);
  };

  const submitEndereco = () => {
    adicionarNovo();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setIdTemp(0);
  };

  const handleSucess = () => {
    setOpenDialog(false);

    enderecoActions.delete(idTemp).then(() => {
      const sub = enderecos.filter((f) => f.id !== idTemp);
      setEnderecos(sub);
      setIdTemp(0);
      toast.success('Endereço excluído');
    });
  };

  const adicionarNovo = async () => {
    setLoadingSave(true);
    endereco.idTipo = 3;
    if ((!endereco?.idContato || endereco?.idContato <= 0) && Number(auth?.id!) > 0) {
      endereco!.idContato = Number(auth?.id!);
    }

    if (endereco.id && endereco.id > 0) {
      const response = await enderecoActions.update(Number(endereco.id), endereco!);
      const newList = enderecos.filter((f) => f.id !== response!.id);
      setEnderecos(newList.concat(response!));
    } else {
      const response = await enderecoActions.create(endereco!);
      const newList = enderecos.concat(response!);
      setEnderecos(newList);
    }

    setEndereco(emptyEndereco(''));
    toast.success('Endereço salvo');
    openDrawerEndereco(false);
    setLoadingSave(false);
  };

  const handleGetCep = (event: any) => {
    if (event.target.value.length <= 9) {
      let input = event.target;
      input.value = getCepMask(input.value);
      setEndereco({ ...endereco, cep: input.value });
    }
    if (event.target.value.length < 9) {
      if (cepValido === true) {
        setEndereco(emptyEndereco(event.target.value));
        setCepValido(false);
      }
      return;
    }

    setLoadingCep(true);
    const enderecoCep = JSON.parse(getUrlViaCep(event.target.value));
    if (enderecoCep !== null && !enderecoCep.erro) {
      setCepValido(true);
      setEndereco({
        id: endereco.id,
        cep: event.target.value,
        bairro: enderecoCep.bairro,
        cidade: enderecoCep.localidade,
        endereco: enderecoCep.logradouro,
        estado: enderecoCep.uf,
        uf: enderecoCep.uf,
      });
    } else {
      setCepValido(false);
      setEndereco(emptyEndereco(event.target.value));
    }
    setLoadingCep(false);
  };

  const emptyEndereco = (cep: string) => {
    return {
      id: endereco.id,
      idContato: Number(auth?.id!),
      idTipo: 3,
      cep: cep,
      bairro: '',
      cidade: '',
      endereco: '',
      estado: '',
    };
  };

  const getCepMask = (value: any) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
    return value;
  };

  return (
    <>
      <TableCrud
        handleClickDelete={handleApagar}
        handleClickEdit={handleEditar}
        handleClickAdd={openDrawerEndereco}
        labelTitle="Endereços"
        labelAddButton='endereço'
        rows={enderecos}
        columns={[
          "cep",
          "endereco",
          "numero",
          "complemento",
          "cidade",
          "uf",
          "principal",
        ]}
        columnsLabels={[
          "CEP",
          "Endereço",
          "Numero",
          "Complemento",
          "Cidade",
          "Estado",
          "Principal",
        ]}
        loadingGet={loadingGet}
      />

      <React.Fragment>
        <Dialog
          open={open}
          onClose={() => openDrawerEndereco(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { borderRadius: 8 }
          }}
        >
          <DialogAlert>
            <Grid sx={{ paddingLeft: "20px" }}>
              <GridIcon>
                <img src={FeaturedIcon} />
              </GridIcon>
            </Grid>
            <DialogTitleDailog id="alert-dialog-title">
            { endereco?.id && endereco?.id > 0 ? 'Alteração de ' : 'Adicionar '} Endereço
            </DialogTitleDailog>
            <DialogContentDailog>
              <DialogContentText id="alert-dialog-description">
                <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', marginTop: '-10px', marginBottom: '20px' }}>
                  <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', paddingRight: '5px' }}>
                    <Grid xs={7} md={7}>
                      <LabelInput>CEP</LabelInput>
                      <FormControl id="cep" required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                        <TextFieldStato
                          type="text"
                          value={endereco?.cep}
                          onChange={(e) => handleGetCep(e)}
                          inputProps={{ maxLength: 9 }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={5} md={5}>
                    </Grid>
                    {loadingCep && <LinearProgress />}
                  </Grid>
                  <Grid container xs={7} sm={7} md={7} sx={{ display: 'flex', paddingRight: '5px' }}>
                    <Grid xs={12} md={12}>
                      <LabelInput>Endereço</LabelInput>
                      <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                        <TextFieldStato
                          id={'EnderecoInput'}
                          type="Endereco"
                          value={endereco?.endereco}
                          disabled={cepValido}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEndereco({ ...endereco, endereco: event.target.value });
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid xs={12} md={12}>
                      <LabelInput>Complemento</LabelInput>
                      <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                        <TextFieldStato
                          id={'EnderecoInput'}
                          type="complemento"
                          value={endereco?.complemento}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEndereco({
                              ...endereco,
                              complemento: event.target.value,
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid container xs={12} md={12}>
                      <Grid container xs={7} md={7} sx={{ display: 'flex', paddingRight: '5px' }}>
                        <LabelInput>Cidade</LabelInput>
                        <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                          <TextFieldStato
                            id={'EnderecoInput'}
                            type="cidade"
                            disabled={cepValido}
                            value={endereco?.cidade}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setEndereco({ ...endereco, cidade: event.target.value });
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid container xs={5} md={5} sx={{ display: 'flex', paddingLeft: '5px' }}>
                        <LabelInput>Estado</LabelInput>
                        <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                          <TextFieldStato
                            id={'EnderecoInput'}
                            type="estado"
                            value={endereco?.uf}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setEndereco({ ...endereco, uf: event.target.value });
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid container xs={5} sm={5} md={5} sx={{ display: 'flex', paddingLeft: '5px' }}>
                    <Grid xs={12} md={12}>
                      <LabelInput>Número</LabelInput>
                      <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                        <TextFieldStato
                          id={'EnderecoInput'}
                          type="numero"
                          value={endereco?.numero}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEndereco({ ...endereco, numero: event.target.value });
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={12}>
                      <LabelInput>Bairro</LabelInput>
                      <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                        <TextFieldStato
                          id={'EnderecoInput'}
                          type="bairro"
                          disabled={cepValido}
                          value={endereco?.bairro}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEndereco({ ...endereco, bairro: event.target.value });
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid xs={12} md={12}>
                      <LabelInput>País</LabelInput>
                      <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                        <TextFieldStato
                          id={'EnderecoInput'}
                          type="pais"
                          value={endereco?.pais}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEndereco({ ...endereco, pais: event.target.value });
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: '5px' }}>
                    <FormControlLabel
                      required
                      sx={{ marginTop: '20px' }}
                      label="Principal"
                      control={
                        <Checkbox onClick={() => setEndereco({ ...endereco, principal: !endereco.principal })} />
                      }
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContentDailog>
            <DialogActions sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              <ButtonAlertClose variant="outlined" onClick={() => openDrawerEndereco(false)}>
                Cancelar
              </ButtonAlertClose>
              <ButtonAlert variant="contained" onClick={submitEndereco} autoFocus>
                Salvar
              </ButtonAlert>

            </DialogActions>
          </DialogAlert>
        </Dialog>
      </React.Fragment >

      <AlertComponent
        open={openDialog}
        labelTitle="Deseja excluir este endereço?"
        labelDescription="O endereço será removido permanentemente dos seus dados cadastrados"
        labelBtnClose="Cancelar"
        labelBtnSucess="Excluir"
        handleClose={handleClose}
        handleSucess={handleSucess}
      />
    </>
  );
};

const ButtonAlertClose = styled(Button)`
width: 170px !important;
height: 44px !important;

font-family: Inter !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;

color: #414651 !important;
border: 1px solid #D5D7DA !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

border-radius: 8px !important;
margin-right: 10px !important;
`;

const ButtonAlert = styled(Button)`
width: 170px !important;
height: 44px !important;
padding: 10px 18px 10px 18px !important;
gap: 8px !important;
border-radius: 8px !important;
border: 1px 0px 0px 0px !important;
opacity: 0px !important;

background: #118BD7 !important;
border: 1px solid #118BD7 !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

`;

const DialogAlert = styled(Grid)`
width: 480px !important;
padding: 20px !important;
`;

const DialogTitleDailog = styled(DialogTitle)`
//styleName: Text lg/Semibold !important;
font-family: Inter !important;
font-size: 18px !important;
font-weight: 600 !important;
line-height: 28px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #181D27 !important;
font-size: 22px !important;

`;

const DialogContentDailog = styled(DialogContent)`
//styleName: Text sm/Regular !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #535862 !important;
`;

const GridIcon = styled(Grid)`
width: 60px !important;
height: 60px !important;
gap: 0px !important;
border-radius: 28px !important;
border: 8px 0px 0px 0px !important;
opacity: 0px !important;
Size="lg" !important;
Color="Warning" !important;
Theme="Light circle outline" !important;

display: flex !important;
justify-content: center !important;
align-items: center !important;
`;

const LabelInput = styled(InputLabel)`
//styleName: Text sm/Medium !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
margin-bottom: -15px !important;
margin-top: 20px !important;
`;

export default EnderecosComponent;
