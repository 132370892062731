import { Divider } from '@mui/material';
import styled from 'styled-components';

export const VerticalDivider = styled(Divider)`
  height: 100%;
  width: 4px;
  max-height: 100vw;
  position: relative;
  background: linear-gradient(
    0deg,
    var(--green-light) 0%,
    var(--blue-light) 50%,
    var(--blue-light) 100%
  );
`;
