import { createTheme } from '@mui/material';
import { ptBR as ptBrGrid } from '@mui/x-data-grid/locales';
import { ptBR } from '@mui/x-date-pickers/locales';

export const theme = createTheme(
  {
    typography: {
      fontFamily: ['Inter', 'Roboto Slab', 'Arial', 'sans-serif'].join(','),
      h1: {
        fontFamily: ['Roboto Slab', 'Arial', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontSize: '64px',
        lineHeight: '82px',
        fontWeight: '300',
        background: 'linear-gradient(90deg, var(--blue-light) 0%, var(--green-light) 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        display: 'inline-block',
      },
      h2: {
        fontFamily: ['Roboto Slab', 'Arial', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontSize: '40px',
        lineHeight: '40px',
        letterSpacing: '-0.03em',
        fontWeight: 300,
        color: 'var(--green-light)',
      },
      h3: {
        fontSize: '30px',
        lineHeight: '38px',
        fontStyle: 'normal',
        fontWeight: 600,
        color: 'var(--green-medium)',
      },
      h4: {
        fontSize: '24px',
        lineHeight: '32px',
        fontStyle: 'normal',
        fontWeight: 600,
        color: 'var(--blue-very-dark)',
      },
      h5: {
        fontSize: '18px',
        lineHeight: '28px',
        fontStyle: 'normal',
        fontWeight: 600,
        color: 'var(--blue-very-dark)',
      },
      h6: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '32px',
        color: 'var(--blue-dark)',
      },
      subtitle1: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: 'var(--blue-very-dark)',
      },
      subtitle2: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '24px',
        color: 'var(--blue-dark)',
      },
      body1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        color: 'var(--gray-medium)',
      },
      body2: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        color: 'var(--gray-medium)',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {},
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
        },
      },
    },
  },
  ptBR,
  ptBrGrid,
);
