import styled from 'styled-components';

const Footer: React.FC = () => {
  return (
    <DivFooter>
      <TextFooter>2024 © STATO. Todos os direitos reservados. Política de Privacidade</TextFooter>
    </DivFooter>
  );
};

const DivFooter = styled.div`
  background: #e9eaeb;
  padding: 0 32px;
  display: flex;
  height: 80px;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 576px) {
    text-align: center;
  }
`;

const TextFooter = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: var(--gray-medium);
  align-content: center;
`;

export default Footer;
