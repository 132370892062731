import React from 'react';
import { Button, Grid, Icon, LinearProgress, TableCell, Typography } from '@mui/material';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Edit from '../../assets/iconsUX/edit.png';
import Delete from '../../assets/iconsUX/remover.png';
import Plus from '../../assets/iconsUX/plus.png';
import CheckGreen from '../../assets/iconsUX/check_green.png';

interface ITableCrud {
    handleClickDelete?: (id: number) => void;
    handleClickDeleteRow?: (row: any) => void;
    handleClickEdit?: (obj: any) => void;
    handleClickEditRow?: (obj: any) => void;
    handleClickAdd: (flag: boolean) => void;
    labelTitle: string;
    labelAddButton: string;
    rows: any[];
    columns: string[];
    columnsLabels: string[];
    loadingGet: boolean;
}

const TableCrud: React.FC<ITableCrud> = ({
    handleClickDelete,
    handleClickDeleteRow,
    handleClickEdit,
    handleClickEditRow,
    handleClickAdd,
    labelTitle,
    labelAddButton,
    rows,
    columns,
    columnsLabels,
    loadingGet }) => {

    const TableContainerCss = {
        borderRadius: "0px 0px 15px 15px",
        maxHeight: 250,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
        },
    }

    return (
        <>
            <GridCard xs={12} md={12} sx={{ backgroundColor: 'white' }}>
                <Grid xs={12} md={12} sx={{ padding: '20px 0px' }}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Grid
                            item
                            xs={6}
                            md={6}
                            sx={{
                                display: 'flex', justifyContent: 'flex-start', alignItems: 'center'
                            }}
                        >
                            <Typography sx={{ padding: '0px 0px 0px 20px' }} variant="h5" component="h2">
                                {labelTitle}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            md={6}
                            sx={{
                                display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0px 20px 0px 0px'
                            }}
                        >
                            <ButtonAdd
                                sx={{}}
                                onClick={() => handleClickAdd(true)}
                                variant="contained"
                            >
                                <img src={Plus} style={{ width: "20px", height: "20px" }} /> Adicionar {labelAddButton}
                            </ButtonAdd>
                        </Grid>
                    </Grid>
                    {/* <Divider orientation="horizontal" /> */}
                </Grid>

                <Grid container xs={12} md={12}>
                    <Grid container xs={12} md={12}>
                        <TableContainer component={Paper} sx={TableContainerCss}>
                            <Table stickyHeader size="small" aria-label="a dense table">
                                <TableHead sx={{ minWidth: 650 }}>
                                    <TableRow>
                                        {columnsLabels.map((row: string) => (
                                            <TableCell sx={{ color: "#616161", backgroundColor: '#f3f3f3 !important' }}>{row}</TableCell>
                                        ))}
                                        <TableCell sx={{ color: "#616161", backgroundColor: '#f3f3f3 !important' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{}}>
                                    {rows.map((row: any) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&: tr': { padding: '20px' } }}>
                                            {columns.map((column: string) => (
                                                (row[column] === true ?
                                                    <TableCell>
                                                        <Grid sx={{ display: "flex", padding: "0px 0px 0px 20px" }}>
                                                            <img src={CheckGreen} style={{ width: "20px", height: "20px" }} />
                                                        </Grid>
                                                    </TableCell>
                                                    :
                                                    <TableCell>{row[column]}</TableCell>
                                                )
                                            ))}
                                            <TableCell>
                                                <Grid container xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Grid sx={{ width: '40px', height: '40px' }}>
                                                        <Icon sx={{ cursor: 'pointer', width: "40px", height: "40px" }} onClick={() =>handleClickDelete ? handleClickDelete(row.id!) : handleClickDeleteRow && handleClickDeleteRow(row)}>
                                                            {' '}
                                                            <img src={Delete} style={{ width: "40px", height: "40px" }} />{' '}
                                                        </Icon>
                                                    </Grid>
                                                    <Grid sx={{ width: '40px', height: '40px' }}>
                                                        <Icon
                                                            sx={{ cursor: 'pointer', width: "40px", height: "40px" }}
                                                            onClick={() => handleClickEdit ? handleClickEdit(rows.find((f) => f.id === row.id!)!) : handleClickEditRow && handleClickEditRow(row)}
                                                        >
                                                            {' '}
                                                            <img src={Edit} style={{ width: "40px", height: "40px" }} />{' '}
                                                        </Icon>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {loadingGet && (
                            <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <LinearProgress sx={{ width: '98%' }} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>

            </GridCard>

        </>
    );
};

const GridCard = styled(Grid)`
box-shadow: 0px 1px 3px 0px rgba(10, 13, 18, 0.1);
border-radius: 15px;
`;

const ButtonAdd = styled(Button)`
width: Hug (193px)px !important;
height: Hug (40px)px !important;
padding: 8px 14px 8px 14px !important;
gap: 8px !important;

background: #118BD7 !important;
`;

export default TableCrud;
