import { useBaseOneActions } from '../base.actionsOne';
import { areaPosicaoAtom } from './AreaPosicaoState';


function useAreaPosicaoActions() {
  const baseActions = useBaseOneActions('areaPosicoes', areaPosicaoAtom);

  return {
    get,
    list: baseActions.list
  };

  async function get(salvarNoState: boolean = false) {
    return await baseActions.list(salvarNoState);
  }
}

export { useAreaPosicaoActions };
