import { Divider, IconButton, Typography } from '@mui/material';
import { Squash as HamburgerSquash } from 'hamburger-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IMenuItens } from '.';
import { VerticalDivider } from '../../commons/styleds/dividers';
import { history } from '../../helpers/history';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSetRecoilState } from 'recoil';
import { authAtom } from '../../states/usuarios/AuthState';

interface MenuMobileProps {
  menuItens: IMenuItens[];
}

const MenuMobile: React.FC<MenuMobileProps> = ({ menuItens }) => {
  const setAuth = useSetRecoilState(authAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSub, setIsOpenSub] = useState(false);
  const [selecteds, setSelecteds] = useState<IMenuItens[]>([]);

  const closeMenu = () => {
    setIsOpen(false);
    setIsOpenSub(false);
    setSelecteds([]);
  };

  const closeSubMenu = () => {
    setIsOpenSub(false);
    setSelecteds([]);
  };

  const onClickMenu = (item: IMenuItens) => {
    if (!item.filhos || item.filhos.length === 0) {
      closeMenu();
      history.push(item.link);
      return;
    }

    setSelecteds((prev) => [...prev, item]);
    setIsOpenSub(true);
  };

  const onClickNavMenu = (item: IMenuItens) => {
    closeSubMenu();
    closeMenu();
    history.push(item.link);
  };

  const handleCloseSair = () => {
    localStorage.removeItem('usuario');
    setAuth(null);
    history.push('/login');
  };

  return (
    <>
      <div>
        <HamburgerSquash toggled={isOpen} toggle={setIsOpen} />
      </div>

      <Backdrop isOpen={isOpen} onClick={closeMenu} />

      <MenuContainer isOpen={isOpen}>
        <MenuHeader>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FlagContainer>
                  <FlagImage
                    src="https://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg"
                    alt="BR"
                  />
                </FlagContainer>
                Português
              </div>
              <div>
                <IconButton color="inherit" onClick={() => handleCloseSair()}>
                  <Typography variant="body1" sx={{ marginRight: '8px' }}>
                    Sair
                  </Typography>
                  <LogoutIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </MenuHeader>

        <Divider />

        <MenuList>
          {menuItens.map((item, index) => (
            <MenuItem key={index} onClick={() => onClickMenu(item)}>
              <Typography variant="body1">{item.nome}</Typography>
            </MenuItem>
          ))}
        </MenuList>

        <BackdropSub isOpen={isOpenSub} onClick={closeSubMenu} />

        <SubMenuContainer isOpen={isOpenSub}>
          <VerticalDividerMenu />

          <div style={{ width: '100%', paddingLeft: '24px', paddingTop: '16px' }}>
            {selecteds.map((item, index) => (
              <>
                <TypographyNav variant="body1" key={index} onClick={() => onClickNavMenu(item)}>
                  {item.nome}
                </TypographyNav>
                {item.nome != selecteds[selecteds.length - 1].nome ? (
                  <NavigateNextRoundedIcon />
                ) : (
                  ''
                )}
              </>
            ))}

            {selecteds.length > 0 && (
              <MenuList>
                {selecteds[selecteds.length - 1]?.filhos?.map((subMenuItem, index) => (
                  <MenuItem key={index} onClick={() => onClickMenu(subMenuItem)}>
                    <Typography variant="body1">{subMenuItem.nome}</Typography>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </div>
        </SubMenuContainer>
      </MenuContainer>
    </>
  );
};

const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 104px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: ${(props) => (props.isOpen ? 'all' : 'none')};
`;

const BackdropSub = styled(Backdrop)<{ isOpen: boolean }>`
  z-index: 1001;
`;

const MenuContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 104px;
  right: ${(props) => (props.isOpen ? '0' : '-576px')};
  width: 100vw;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.6s ease;
  z-index: 1000;
  padding: 24px;
`;

const MenuHeader = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
`;

const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 1em 0;

  li:last-child {
    margin-bottom: 0 !important;
  }
`;

const MenuItem = styled.li`
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 8px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const SubMenuContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 104px;
  right: ${(props) => (props.isOpen ? '0' : '-576px')};
  width: 88vw;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.6s ease;
  z-index: 1002;
  display: flex;
`;

const DivSubMenuContainer = styled.div`
  padding: 24px;
`;

const VerticalDividerMenu = styled(VerticalDivider)`
  min-height: 88vh;
`;

const TypographyNav = styled(Typography)`
  cursor: pointer;
  color: var(--blue-light) !important;
`;

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Arial, sans-serif;
  margin-right: 8px;
`;

const FlagImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
`;

export default MenuMobile;
