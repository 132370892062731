import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from 'styled-components';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { history } from '../../helpers/history';
import { authAtom } from '../../states/usuarios/AuthState';
import { useUsuarioActions } from '../../states/usuarios/usuario.actions';
import { FormControlStato, GridContainer } from '../../commons/styleds/styleds';
import { GridItem } from '../../components/Menu';
import { useMenusActions } from '../../states/menus/menu.actions';
import { Link } from 'react-router-dom';
import { DividerMenu } from '../../components/Header';
import Footer from '../../components/Footer';
import { Box, Paper } from '@mui/material';
import Logo from './../../assets/STATO-INTOO-LOGO-MY-INTOO-VERS-2 A.png';
import ImgBackground from '../../assets/intoo_E.png';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const Login: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const usuarioActions = useUsuarioActions();
  const [email, setEmail] = useState<string>('');
  const [senha, setSenha] = useState<string>('');

  const [mostrarSenha, setMostrarSenha] = useState(false);

  const handleClickMostrarSenha = () => setMostrarSenha((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    usuarioActions
      .login(email, senha)
      .then((d) => {})
      .catch((erro) => {
        toast.error(erro);
      });
  };

  useEffect(() => {
    if (auth!!) history.push('/');
  }, [auth]);

  return (
    <>
      <DividerMenu />
      <DivBackground>
        <Wrapper>
          <PaperLogin>
            <img src={Logo} alt="logo" style={{ width: 'auto', height: '80px' }} />
            <TextoBemVindo variant="h5">Bem-vindo ao portal Stato Intoo</TextoBemVindo>
            <form onSubmit={onSubmit} style={{ marginTop: '20px' }}>
              <FormControlStato sx={{ width: '100%', marginBottom: '12px' }} variant="filled">
                <TextFieldLogin
                  id="filled-basic"
                  type="email"
                  placeholder="E-mail"
                  required
                  variant="outlined"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </FormControlStato>
              <FormControlStato sx={{ width: '100%' }} variant="filled">
                <TextFieldLogin
                  id="filled-basic"
                  placeholder="Senha"
                  required
                  variant="outlined"
                  type={mostrarSenha ? 'text' : 'password'}
                  value={senha}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSenha(event.target.value);
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickMostrarSenha}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </FormControlStato>
              <Typography variant="body2" sx={{ marginY: '32px', textAlign: 'center' }}>
                Esqueceu a senha?{' '}
                <Link to="/redefinir-senha" color={'primary.main'}>
                  Clique Aqui
                </Link>
                .
              </Typography>
              <Button type="submit" variant="contained" fullWidth>
                Entrar
              </Button>
            </form>
          </PaperLogin>
        </Wrapper>
      </DivBackground>
      <Footer />
    </>
  );
};

export const DivBackground = styled.div`
  background-image: url(${ImgBackground});
  background-repeat: no-repeat;
  background-position: top right;
  background-position-y: -150px;
  background-attachment: fixed;
  background-size: 60%;
  width: 100%;
  height: calc(100vh - 85px);

  @media (max-width: 576px) {
    background-position: top;
    background-position-y: unset;
    background-size: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PaperLogin = styled(Paper)`
  width: 400px;
  height: 424px;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 576px) {
    width: 312px;
    height: 452px;
  }
`;

export const ContainerLogin = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const TextoBemVindo = styled(Typography)`
  @media (max-width: 576px) {
    max-width: 200px;
    text-align: center;
  }
`;

export const TextFieldLogin = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 8px; /* Raio de borda */

    & :hover {
      border-color: #b0b0b0; /* Cor ao passar o mouse */
    }

    & .Mui-focused {
      border-color: #007bff; /* Cor ao focar */
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d6d7da; /* Borda personalizada */
  }
`;

export default Login;
