/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import {
    Button,
    FormControl,
    Box,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    Accordion,
    InputLabel,
    MenuItem,
    createFilterOptions,
} from '@mui/material';
import { useEmpresasActions } from '../../../states/empresas/empresa.actions';
import { IContatoHistoricoEmpresa, INivelPosicao } from '../../../commons/types/crm';
import {
    ButtonStato,
    SelectStato,
    TextFieldStato,
} from '../../../commons/styleds/styleds';
import { GridItemCadastro } from '../../../commons/styleds/grids';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/usuarios/AuthState';
import moment from 'moment';
import dayjs from 'dayjs';
import { AccordionDetails, AccordionSummary } from '../accordionAndTabPanel';
import {
    IAreaPosicao,
    IContatoHistoricoEmpresaPosicao,
    IEmpresaType,
    IEscopoResonsabilidade,
    IHistorico,
} from '../../../commons/styleds/historicoProdissional';
import { useContatoHistoricoEmpresaActions } from '../../../states/contatoHistoricoEmpresas/ContatoHistoricoEmpresaActions';
import { useAreaPosicaoActions } from '../../../states/areaPosicoes/areaPosicao.actions';
import { useContatoHistoricoEmpresaPosicoesActions } from '../../../states/ContatoHistoricoEmpresaPosicao/ContatoHistoricoEmpresaPosicoesActions';
import { useEscopoResponsabilidadeActions } from '../../../states/escopoResponsabilidade/escopoResponsabilidade.actions';
import { useNivelPosicaoActions } from '../../../states/nivelPosicao/nivelPosicao.actions';
import TableCrud from '../../../components/TableCrud/TableCrud';
import AlertComponent from '../../../components/Alert/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FeaturedIcon from '../../../assets/iconsUX/Featured icon.png';
import { styled } from 'styled-components';

interface Props {
    novoUsuario: boolean;
}

export const filter = createFilterOptions<IEmpresaType>();

const emptyPosicao: any = {
    id: 0,
    historicoEmpresaId: 0,
    cargo: '',
    realizacoes: '',
    responsabilidades: '',
    motivoSaida: '',
    superior: '',
    cargoAtual: false,
    numeroDeSubordinados: 0,
    areaId: 0,
    nivelId: 0,
    escopoId: 0,
    dataInicio: null,
    dataTermino: null,
};

const HistoricoProfissionalComponent: React.FC = () => {
    const auth = useRecoilValue(authAtom);
    const [open, setOpen] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [editar, setEditar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingGet, setLoadingGet] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [idTemp, setIdTemp] = useState<number>(0);

    const [apagadoTemp, setApagadoTemp] = useState<number[]>([0]);

    const contatoHistoricoEmpresaActions = useContatoHistoricoEmpresaActions();
    const contatoHistoricoEmpresaPosicoesActions = useContatoHistoricoEmpresaPosicoesActions();
    const empresaActions = useEmpresasActions();
    const areaActions = useAreaPosicaoActions();
    const nivelActions = useNivelPosicaoActions();
    const escopoActions = useEscopoResponsabilidadeActions();

    const [contatoHistoricoEmpresas, setContatoHistoricoEmpresas] = useState<
        IContatoHistoricoEmpresa[]
    >([]);
    const [contatoHistoricoEmpresa, setContatoHistoricoEmpresa] =
        useState<IContatoHistoricoEmpresa>();

    const [tableData, setTableData] = useState<IHistorico[]>([]);
    const [value, setValue] = useState<IEmpresaType | null>(null);
    const [openEmpresaDialog, setOpenEmpresaDialog] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        razaosocial: '',
        cnpj: '',
    });

    const [empresas, setEmpresas] = useState<IEmpresaType[]>([]);
    const [areasPosicao, setAreasPosicao] = useState<IAreaPosicao[]>([]);
    const [niveisPosicao, setNiveisPosicao] = useState<INivelPosicao[]>([]);
    const [escoposPosicao, setEscoposPosicao] = useState<IEscopoResonsabilidade[]>([]);

    const { control, handleSubmit, register, watch, reset } = useForm<IContatoHistoricoEmpresa>({
        defaultValues: {
            posicoes: [emptyPosicao],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'posicoes',
    });

    const posicoesValue = watch('posicoes');

    const resetValues = () => {
        setContatoHistoricoEmpresa(undefined);
        setValue(null);
        reset({ posicoes: [emptyPosicao] });
    };

    const onSubmit = async (data: IContatoHistoricoEmpresa) => {
        if (!value) {
            alert('É necessário selecionar uma empresa');
            return;
        }

        if (data.posicoes.filter(f => f.dataInicio == null).length > 0) {
            toast.warning('Preencha a data inicial das posicoes');
            return;
        }

        setLoadingSave(true);

        if (!contatoHistoricoEmpresa) await adicionarNovoHistorico(data);
        else await editarHistorico(data);

        setLoadingSave(false);
        openDrawerHistorico(false);
        getContatoHistoricoEmpresas();
    };

    const adicionarNovoHistorico = async (data: IContatoHistoricoEmpresa) => {
        const historicoEmpresa = {
            contatoId: auth?.id,
            empresaId: value?.id,
        };

        const resp = await contatoHistoricoEmpresaActions.create(historicoEmpresa);
        await data.posicoes?.forEach(async (posicao: IContatoHistoricoEmpresaPosicao) => {
            const req = {
                ...posicao,
                historicoEmpresaId: resp.id,
                dataInicio: posicao.dataInicio ? posicao.dataInicio.toISOString() : null,
                dataTermino: posicao.dataTermino ? posicao.dataTermino.toISOString() : null,
            };
            const posicaoResp = await contatoHistoricoEmpresaPosicoesActions.create(req);
            resp.posicoes.push(posicaoResp);
        });
        toast.success('Histórico cadastrado');
    };

    const editarHistorico = async (data: IContatoHistoricoEmpresa) => {
        const posicoesNovas: IContatoHistoricoEmpresaPosicao[] = [];
        const posicoesAlteradas: IContatoHistoricoEmpresaPosicao[] = [];
        const posicoesRemovidas: IContatoHistoricoEmpresaPosicao[] = [];

        data.posicoes?.forEach((posicao) => {
            const req = {
                ...posicao,
                historicoEmpresaId: contatoHistoricoEmpresa!.id,
                dataInicio: posicao.dataInicio ? posicao.dataInicio.toISOString() : null,
                dataTermino: posicao.dataTermino ? posicao.dataTermino.toISOString() : null,
            } as any;
            if (posicao.id === 0)
                posicoesNovas.push(req);
            else if (posicao.apagou)
                posicoesRemovidas.push(posicao);
            else if (contatoHistoricoEmpresa?.posicoes?.map((p) => p.id)?.includes(posicao?.id))
                posicoesAlteradas.push(req);
            else
                posicoesRemovidas.push(posicao);
        });

        const newRequests = posicoesNovas.map((posicao) =>
            contatoHistoricoEmpresaPosicoesActions.create(posicao),
        );

        const updateRequests = posicoesAlteradas.map((posicao) =>
            contatoHistoricoEmpresaPosicoesActions.update(posicao.id!, posicao),
        );

        const deleteRequests = apagadoTemp.filter((posicao) => posicao !== 0).map((posicao) =>
            contatoHistoricoEmpresaPosicoesActions.delete(posicao),
        );

        await Promise.all([...newRequests, ...updateRequests, ...deleteRequests]);

        setApagadoTemp([0]);
        toast.success('Historico alterado');
    };

    const removerPosicao = (index: number) => {
        remove(index);
        let id = posicoesValue[index].id;
        setApagadoTemp([...apagadoTemp, id]);
    };

    useEffect(() => {
        setLoadingGet(true);
        getContatoHistoricoEmpresas();
        getEmpresas();
        getAreas();
        getNiveis();
        getEscopos();

        setLoading(false);
    }, []);

    const getContatoHistoricoEmpresas = () => {
        contatoHistoricoEmpresaActions
            .list(false, `?contatoId=${auth?.id}`)
            .then((data: IContatoHistoricoEmpresa[]) => {
                setContatoHistoricoEmpresas(data);

                const dataTable = data.map((c: IContatoHistoricoEmpresa) => {
                    const posicoesSorted =
                        c.posicoes!.length > 0
                            ? c.posicoes?.sort(
                                (a, b) =>
                                    new Date(a.dataInicio as any).getTime() -
                                    new Date(b.dataInicio as any).getTime(),
                            )
                            : [];
                    return {
                        id: c.id,
                        empresa: c.empresaIdNavigation?.razaosocial,
                        dataInicio: posicoesSorted?.length ? formatDate(posicoesSorted[0]?.dataInicio) : null,
                        dataFim: posicoesSorted?.length
                            ? formatDate(posicoesSorted[posicoesSorted.length - 1]?.dataTermino)
                            : null,
                        atual: posicoesSorted?.length
                            ? posicoesSorted[posicoesSorted.length - 1].cargoAtual.toString()
                            : 'false',
                    } as IHistorico;
                });

                setTableData(dataTable);
                setLoadingGet(false);
            })
            .catch((error: any) => {
                setLoadingGet(false);
                toast.error(error);
            });
    };

    const formatDate = (date: Date | any) => {
        return moment(date).format('DD/MM/YYYY');
    };

    const getEmpresas = () => {
        empresaActions
            .list()
            .then((resp: any) => {
                setEmpresas(resp);
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao buscar empresas');
            });
    };

    const getAreas = () => {
        areaActions
            .list()
            .then((resp: any) => {
                setAreasPosicao(resp);
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao buscar Areas Posição');
            });
    };

    const getNiveis = () => {
        nivelActions
            .list()
            .then((resp: any) => {
                setNiveisPosicao(resp);
            })
            .catch((e: any) => {
                toast.error('Falha ao buscar Níveis Posição');
            });
    };

    const getEscopos = () => {
        escopoActions
            .list()
            .then((resp: any) => {
                setEscoposPosicao(resp);
            })
            .catch((e: any) => {
                toast.error('Falha ao buscar Escopos de Responsabilidades');
            });
    };

    const atualizarFormulario = (result: any) => {
        setLoadingForm(true);

        const contHist = contatoHistoricoEmpresas.find((c) => c.id === result.id);
        if (contHist === undefined) return;

        setContatoHistoricoEmpresa(contHist);
        setValue(empresas.find((e) => e.id === contHist!.empresaId)!);

        const posicoesMapped = contHist.posicoes!.map((element: IContatoHistoricoEmpresaPosicao) => ({
            ...element,
            dataInicio: dayjs(element.dataInicio?.toString()),
            dataTermino: dayjs(element.dataTermino?.toString()),
        }));
        reset({ posicoes: posicoesMapped });
        openDrawerHistorico(true);
        setTimeout(() => {
            setLoadingForm(false);
        }, 1500);
    };

    const openDrawerHistorico = (result: any) => {
        setOpen(result);
        setApagadoTemp([0]);
        if (!result) resetValues();
    };

    const HandleApagar = (obj: any) => {
        setLoading(true);
        setIdTemp(obj.id);
        setOpenDialog(true);
    };

    const handleCloseDailog = () => {
        setOpenDialog(false);
        setApagadoTemp([0]);
        setIdTemp(0);
    };

    const handleSucess = () => {
        setOpenDialog(false);

        contatoHistoricoEmpresaActions
            .delete(idTemp)
            .then(() => {
                const sub = tableData.filter((f) => f.id !== idTemp);
                setTableData(sub);
                const subContato = contatoHistoricoEmpresas.filter((f) => f.id !== idTemp);
                setContatoHistoricoEmpresas(subContato);
                toast.success('Histórico removido com sucesso');
                setIdTemp(0);

            })
            .catch((e: any) => toast.error('Falha ao remover histórico'))
            .finally(() => setLoading(false));
    };

    const AdicionarNaLista = () => {
        openDrawerHistorico(false);
    };

    const handleClose = () => {
        setDialogValue({
            razaosocial: '',
            cnpj: '',
        });
        setOpenEmpresaDialog(false);
    };

    const getUltimoCargo = (row: IContatoHistoricoEmpresa) => {
        let cargoAtual = row.posicoes.find((f) => f.cargoAtual)?.nivelId;

        if (!cargoAtual) {
            cargoAtual = 0;

            const posicaoMaisRecente = row.posicoes
                .filter((f) => f.historicoEmpresaId == row.id)
                .sort((a, b) => {
                    // Ordena por dataTermino em ordem decrescente
                    return new Date(b?.dataTermino.toString()).getTime() - new Date(a?.dataTermino.toString()).getTime();
                })[0]; // Pega a primeira posição após a ordenação

            if (posicaoMaisRecente) {
                cargoAtual = posicaoMaisRecente.nivelId;
            }
        }

        let name = niveisPosicao.find(
            (f) => f.id == cargoAtual
        )?.nome;

        return name;
    };

    const getDataInicio = (row: IContatoHistoricoEmpresa) => {
        let dataInicio = row.posicoes.find((f) => f.cargoAtual)?.dataInicio;

        if (!dataInicio) {
            const posicaoMaisRecente = row.posicoes
                .filter((f) => f.historicoEmpresaId == row.id)
                .sort((a, b) => {
                    // Ordena por dataTermino em ordem decrescente
                    return new Date(b?.dataTermino.toString()).getTime() - new Date(a?.dataTermino.toString()).getTime();
                })[0]; // Pega a primeira posição após a ordenação

            if (posicaoMaisRecente) {
                dataInicio = posicaoMaisRecente.dataInicio;
            }
        }

        let ret = dataInicio ? new Date(dataInicio.toString()).toLocaleDateString() : '';

        return ret;
    };

    const getDataTermino = (row: IContatoHistoricoEmpresa) => {
        let dataTermino = row.posicoes.find((f) => f.cargoAtual)?.dataTermino;

        if (!dataTermino) {
            const posicaoMaisRecente = row.posicoes
                .filter((f) => f.historicoEmpresaId == row.id)
                .sort((a, b) => {
                    // Ordena por dataTermino em ordem decrescente
                    return new Date(b?.dataTermino.toString()).getTime() - new Date(a?.dataTermino.toString()).getTime();
                })[0]; // Pega a primeira posição após a ordenação

            if (posicaoMaisRecente) {
                dataTermino = posicaoMaisRecente.dataTermino;
            }
        }

        let ret = dataTermino != undefined ? dataTermino?.toString() == "0001-01-01T00:00:00" ? "" : new Date(dataTermino?.toString()).toLocaleDateString() : "";
        ret = ret == "01/01/1901" ? "" : ret;

        return ret;
    };

    const handleSubmitEmpresa = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!dialogValue.razaosocial) {
            toast.warn('Preencha todos os campos necessários');
            return;
        }
        const valueSubmit = {
            razaosocial: dialogValue.razaosocial,
            cnpj: dialogValue.cnpj ?? '',
        };
        empresaActions
            .create(valueSubmit)
            .then((data) => {
                setValue(data);
                toast.success('Empresa criada com sucesso');

                empresaActions
                    .list()
                    .then((resp: any) => {
                        setEmpresas(resp);
                        if (value) {
                            setValue(resp.find((e: any) => e.id == value));
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        toast.error('Falha ao buscar empresas');
                    });
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao salvar nova Empresa');
            });

        handleClose();
    };

    const filterOptions = useMemo(() => {
        return (options: IEmpresaType[], params: any) => {
            const filtered = options.filter((option) =>
                option.razaosocial.toLowerCase().includes(params.inputValue.toLowerCase())
            );
            if (params.inputValue !== '') {
                filtered.push({
                    inputValue: params.inputValue,
                    razaosocial: `Adicionar: "${params.inputValue}"`,
                    cnpj: '',
                });
            }
            return filtered;
        };
    }, [empresas]);

    return (
        <>
            <Grid xs={12} md={12} sx={{ padding: '40px 0px' }}>
                <TableCrud
                    handleClickDeleteRow={HandleApagar}
                    handleClickEdit={atualizarFormulario}
                    handleClickAdd={openDrawerHistorico}
                    labelTitle="Históricos Profissionais"
                    labelAddButton='Histórico Profissional'
                    rows={contatoHistoricoEmpresas.map((row: IContatoHistoricoEmpresa) => ({
                        id: row.id,
                        contatoId: row.contatoId,
                        empresaId: row.empresaId,
                        empresaIdNavigation: row.empresaIdNavigation,
                        posicoes: row.posicoes,
                        empresa: row.empresaIdNavigation.razaosocial,
                        ultimoCargo: getUltimoCargo(row),
                        dataInicio: getDataInicio(row),
                        dataFim: getDataTermino(row),
                    }))}
                    columns={[
                        "empresa",
                        "ultimoCargo",
                        "dataInicio",
                        "dataFim",
                    ]}
                    columnsLabels={[
                        "Empresa",
                        "Último Cargo / Posição",
                        "Data Início",
                        "Data Fim",
                    ]}
                    loadingGet={loadingGet}
                />
            </Grid>

            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={() => openDrawerHistorico(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: { borderRadius: 8 }
                    }}
                >
                    <DialogAlert>
                        <Grid sx={{ paddingLeft: "20px" }}>
                            <GridIcon>
                                <img src={FeaturedIcon} />
                            </GridIcon>
                        </Grid>
                        <DialogTitleDailog id="alert-dialog-title">
                            Alteração do Histórico Profissional
                        </DialogTitleDailog>
                        <DialogContentDailog>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', marginTop: '-10px', marginBottom: '20px' }}>
                                    <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '450px' }}>
                                        <GridItemCadastro item xs={12} style={{ marginTop: '30px' }}>
                                            <>
                                                <Autocomplete
                                                    value={value}
                                                    disabled={(contatoHistoricoEmpresa?.id ?? 0) > 0}
                                                    onChange={(event, newValue) => {
                                                        if (typeof newValue === 'string') {
                                                            setTimeout(() => {
                                                                setOpenEmpresaDialog(true);
                                                                setDialogValue({
                                                                    razaosocial: newValue,
                                                                    cnpj: '',
                                                                });
                                                            });
                                                        } else if (newValue && newValue.inputValue) {
                                                            setOpenEmpresaDialog(true);
                                                            setDialogValue({
                                                                razaosocial: newValue.inputValue,
                                                                cnpj: '',
                                                            });
                                                        } else {
                                                            setValue(newValue);
                                                        }
                                                    }}
                                                    filterOptions={filterOptions}
                                                    id="adicionar-empresa-dialog"
                                                    options={empresas}
                                                    getOptionLabel={(option) => {
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        if (option.inputValue) {
                                                            return option.inputValue;
                                                        }
                                                        return option.razaosocial;
                                                    }}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    renderOption={(props, option) => {
                                                        const { key, ...rest } = props;
                                                        return <li key={option.id ?? `${option.razaosocial}-${Math.random()}`} {...rest}>{option.razaosocial}</li>;
                                                    }}
                                                    fullWidth
                                                    freeSolo
                                                    renderInput={(params) => <TextFieldStato {...params} label="Empresa Cliente" />}
                                                />
                                                <Dialog open={openEmpresaDialog} onClose={handleClose}>
                                                    <form>
                                                        <DialogTitle>Adicionar nova Empresa</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                Complete as informações da nova Empresa!
                                                            </DialogContentText>
                                                            <TextFieldStato
                                                                autoFocus
                                                                margin="dense"
                                                                id="razaosocial"
                                                                value={dialogValue.razaosocial}
                                                                onChange={(event) =>
                                                                    setDialogValue({
                                                                        ...dialogValue,
                                                                        razaosocial: event.target.value,
                                                                    })
                                                                }
                                                                label="Razão Social"
                                                                type="text"
                                                            />
                                                            <TextFieldStato
                                                                margin="dense"
                                                                id="cnpj"
                                                                value={dialogValue.cnpj}
                                                                onChange={(event) =>
                                                                    setDialogValue({
                                                                        ...dialogValue,
                                                                        cnpj: event.target.value,
                                                                    })
                                                                }
                                                                label="CNPJ"
                                                                type="text"
                                                            />
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <ButtonStato color="error" onClick={handleClose}>
                                                                Cancelar
                                                            </ButtonStato>
                                                            <ButtonStato onClick={(e: any) => handleSubmitEmpresa(e)}>
                                                                Adicionar
                                                            </ButtonStato>
                                                        </DialogActions>
                                                    </form>
                                                </Dialog>
                                            </>
                                        </GridItemCadastro>

                                        {fields.slice()
                                            .sort((a, b) => {
                                                // First, prioritize cargoAtual
                                                if (a.cargoAtual && !b.cargoAtual) return -1;  // a comes first
                                                if (!a.cargoAtual && b.cargoAtual) return 1;   // b comes first

                                                // If both are current or both are not current, sort by date
                                                if (!a?.dataTermino) return 1;  // Null dates go last
                                                if (!b?.dataTermino) return -1;

                                                // Compare dates for non-current positions
                                                return new Date(b?.dataTermino.toString()).getTime() - new Date(a?.dataTermino.toString()).getTime();
                                            })
                                            .map((field, index) => (
                                                <>
                                                    <Accordion defaultExpanded key={index}>
                                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                            <Typography>Cargo - {index + 1}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                                <Controller
                                                                    name={`posicoes.${index}.areaId` as const}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <FormControl fullWidth>
                                                                            <InputLabel>Área</InputLabel>
                                                                            <SelectStato {...field} label="Área">
                                                                                {areasPosicao.map((area: IAreaPosicao) => (
                                                                                    <MenuItem value={area.id}>{area.nome}</MenuItem>
                                                                                ))}
                                                                            </SelectStato>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                                <Controller
                                                                    name={`posicoes.${index}.nivelId` as const}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <FormControl fullWidth>
                                                                            <InputLabel>Nível</InputLabel>
                                                                            <SelectStato {...field} label="Nível">
                                                                                {niveisPosicao.map((nivel: INivelPosicao) => (
                                                                                    <MenuItem value={nivel.id}>{nivel.nome}</MenuItem>
                                                                                ))}
                                                                            </SelectStato>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                                <Controller
                                                                    name={`posicoes.${index}.escopoId` as const}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <FormControl fullWidth>
                                                                            <InputLabel>Escopo</InputLabel>
                                                                            <SelectStato {...field} label="Escopo">
                                                                                {escoposPosicao.map((escopo: IEscopoResonsabilidade) => (
                                                                                    <MenuItem value={escopo.id}>{escopo.nome}</MenuItem>
                                                                                ))}
                                                                            </SelectStato>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                                <TextFieldStato
                                                                    fullWidth
                                                                    label="Cargo"
                                                                    variant="outlined"
                                                                    {...register(`posicoes.${index}.cargo` as const)}
                                                                />
                                                                <Controller
                                                                    name={`posicoes.${index}.cargoAtual` as const}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <FormControlLabel
                                                                            control={<Checkbox {...field} checked={field.value} />}
                                                                            label="Cargo Atual"
                                                                        />
                                                                    )}
                                                                />
                                                                <Controller
                                                                    name={`posicoes.${index}.dataInicio` as const}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <LocalizationProvider
                                                                            dateAdapter={AdapterDayjs}
                                                                            localeText={{
                                                                                clearButtonLabel: 'Empty',
                                                                                todayButtonLabel: 'Now',
                                                                            }}
                                                                        >
                                                                            <DesktopDatePicker
                                                                                {...field}
                                                                                value={dayjs(field.value)}
                                                                                onChange={(value) => field.onChange(value!.toDate() as Date)}
                                                                                label="Data Início"
                                                                                format="DD/MM/YYYY"
                                                                            />
                                                                        </LocalizationProvider>
                                                                    )}
                                                                />
                                                                {!posicoesValue[index].cargoAtual &&
                                                                    <>
                                                                        <Controller
                                                                            name={`posicoes.${index}.dataTermino` as const}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <LocalizationProvider
                                                                                    dateAdapter={AdapterDayjs}
                                                                                    localeText={{
                                                                                        clearButtonLabel: 'Empty',
                                                                                        todayButtonLabel: 'Now',
                                                                                    }}
                                                                                >
                                                                                    <DesktopDatePicker
                                                                                        {...field}
                                                                                        value={dayjs(field.value)}
                                                                                        onChange={(value) => field.onChange(value!.toDate() as Date)}
                                                                                        label="Data Término"
                                                                                        format="DD/MM/YYYY"
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            )}
                                                                        />
                                                                        <TextFieldStato
                                                                            fullWidth
                                                                            label="Superior (contato de referência)"
                                                                            variant="outlined"
                                                                            {...register(`posicoes.${index}.superior` as const)}
                                                                        />
                                                                        <TextFieldStato
                                                                            fullWidth
                                                                            label="Número de Subordinados"
                                                                            variant="outlined"
                                                                            type="number"
                                                                            {...register(`posicoes.${index}.numeroDeSubordinados` as const)}
                                                                        />
                                                                        <TextFieldStato
                                                                            fullWidth
                                                                            label="Responsabilidades"
                                                                            variant="outlined"
                                                                            multiline
                                                                            minRows={3}
                                                                            {...register(`posicoes.${index}.responsabilidades` as const)}
                                                                        />
                                                                        <TextFieldStato
                                                                            fullWidth
                                                                            label="Realizações"
                                                                            variant="outlined"
                                                                            multiline
                                                                            minRows={3}
                                                                            {...register(`posicoes.${index}.realizacoes` as const)}
                                                                        />
                                                                        <TextFieldStato
                                                                            fullWidth
                                                                            label="Motivo da Saída"
                                                                            variant="outlined"
                                                                            multiline
                                                                            {...register(`posicoes.${index}.motivoSaida` as const)}
                                                                        />
                                                                    </>
                                                                }
                                                                <ButtonStato
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={() => removerPosicao(index)}
                                                                >
                                                                    Apagar
                                                                </ButtonStato>
                                                            </Box>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </>
                                            ))}

                                        <Grid container xs={12} md={12} sm={12}>
                                            <Grid xs={12} md={12} sm={12}>
                                                <ButtonStato type="button" onClick={() => append(emptyPosicao)} sx={{ mt: 2 }}>
                                                    Adicionar Nova Posição
                                                </ButtonStato>
                                            </Grid>
                                            <Grid container xs={12} md={12} sm={12} sx={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                marginTop: "10px"
                                            }}>
                                                <Grid xs={6} md={6} sm={6}>
                                                    <ButtonAlertClose variant="outlined" onClick={() => openDrawerHistorico(false)}>
                                                        Cancelar
                                                    </ButtonAlertClose>
                                                </Grid>
                                                <Grid xs={6} md={6} sm={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "right" }}>
                                                    <ButtonAlert type="submit" variant="contained" sx={{ mt: 2 }}>
                                                        Enviar
                                                    </ButtonAlert>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </DialogContentText>
                        </DialogContentDailog>
                        <DialogActions sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            {/* <ButtonAlertClose variant="outlined" onClick={() => openDrawerHistorico(false)}>
                                Cancelar
                            </ButtonAlertClose>
                            <ButtonAlert variant="contained" onClick={adicionarNovoHistorico} autoFocus>
                                Salvar
                            </ButtonAlert> */}

                        </DialogActions>
                    </DialogAlert>
                </Dialog>
            </React.Fragment>

            <AlertComponent
                open={openDialog}
                labelTitle="Deseja excluir este histórico profissional?"
                labelDescription="O histórico profissional será removido permanentemente dos seus dados cadastrados"
                labelBtnClose="Cancelar"
                labelBtnSucess="Excluir"
                handleClose={handleCloseDailog}
                handleSucess={handleSucess}
            />
        </>
    );
};

const ButtonAlertClose = styled(Button)`
width: 170px !important;
height: 44px !important;

font-family: Inter !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;

color: #414651 !important;
border: 1px solid #D5D7DA !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

border-radius: 8px !important;
margin-right: 10px !important;
`;

const ButtonAlert = styled(Button)`
width: 170px !important;
height: 44px !important;
padding: 10px 18px 10px 18px !important;
gap: 8px !important;
border-radius: 8px !important;
border: 1px 0px 0px 0px !important;
opacity: 0px !important;

background: #118BD7 !important;
border: 1px solid #118BD7 !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

`;

const DialogAlert = styled(Grid)`
width: 550px !important;
padding: 20px !important;
`;

const DialogTitleDailog = styled(DialogTitle)`
//styleName: Text lg/Semibold !important;
font-family: Inter !important;
font-size: 18px !important;
font-weight: 600 !important;
line-height: 28px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #181D27 !important;
font-size: 22px !important;

`;

const DialogContentDailog = styled(DialogContent)`
//styleName: Text sm/Regular !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #535862 !important;
`;

const GridIcon = styled(Grid)`
width: 60px !important;
height: 60px !important;
gap: 0px !important;
border-radius: 28px !important;
border: 8px 0px 0px 0px !important;
opacity: 0px !important;
Size="lg" !important;
Color="Warning" !important;
Theme="Light circle outline" !important;

display: flex !important;
justify-content: center !important;
align-items: center !important;
`;

const LabelInput = styled(InputLabel)`
//styleName: Text sm/Medium !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
margin-bottom: -15px !important;
margin-top: 20px !important;
`;

export default HistoricoProfissionalComponent;
