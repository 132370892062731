import { useBaseActions } from '../base.actions';
import { reuniaosAtom } from './MenuState';
import { history } from '../../helpers/history';
import { authAtom } from '../usuarios/AuthState';
import { useSetRecoilState } from 'recoil';
import { useAxiosWrapper } from '../../services/axiosWrapper';

function useMenusActions() {
  const baseActions = useBaseActions('menus', reuniaosAtom, false);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1`;
  const axiosWrapper = useAxiosWrapper();
  const setAuth = useSetRecoilState(authAtom);

  return {
    get,
    list: baseActions.list,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };

  async function get() {
    try {
      const recurso = await axiosWrapper.get(`${baseUrl}/assessorados/menus`);
      return recurso;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
  }
}

export { useMenusActions };
