import { Typography } from '@mui/material';
import { styled } from 'styled-components';

export interface IEtapaCard {
  children?: any;
  headerTitle: string;
}

const EtapaCard: React.FC<IEtapaCard> = ({ headerTitle, children }) => {
  return (
    <DivCard>
      <HeaderCard>
        <Typography variant="subtitle2">{headerTitle}</Typography>
      </HeaderCard>
      <div style={{ padding: '32px', height: '100%' }}>{children}</div>
    </DivCard>
  );
};

const DivCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 384px;
  height: 456px;
  background: #ffffff;
  border: 1px solid #e9eaeb;
  box-shadow:
    0px 12px 16px -4px rgba(10, 13, 18, 0.08),
    0px 4px 6px -2px rgba(10, 13, 18, 0.03);
  border-radius: 16px;
`;

const HeaderCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 32px;
  gap: 16px;

  width: 384px;
  height: 40px;

  background: #f5faff;
  border-radius: 16px 16px 0px 0px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export default EtapaCard;
