import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ButtonStato = styled(Button)`
  border-radius: 17px !important;
`;

export const OutlinedButton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  border-radius: 8px;
  color: #414651;
  box-sizing: border-box;
  padding: 10px 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d5d7da;
  box-shadow: 0px 1px 2px rgba(10, 13, 18, 0.05);
  border-radius: 8px;
  text-decoration: none;
`;
