import React from 'react';
import { CircularProgress, Typography, Box, Stack } from '@mui/material';
import styled from 'styled-components';

const CustomCircularProgress = styled(CircularProgress)`
  && {
    thickness: 8;
    color: #e9eaeb;
    & .MuiCircularProgress-circle {
      stroke-linecap: round;
    }
  }
`;

const CircularProgressFilled = styled(CircularProgress)`
  && {
    thickness: 8;
    color: ${(props) => props.color};
    & .MuiCircularProgress-circle {
      stroke-linecap: round;
    }
  }
`;

interface IValues {
  value: number;
  color: string;
}

interface ICircularProgressWithMultipleLabels {
  values: IValues[];
  valorExibir: number;
}

const CircularProgressWithMultipleLabels: React.FC<ICircularProgressWithMultipleLabels> = ({
  values,
  valorExibir,
}) => {
  return (
    <Box
      position="relative"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="90%"
    >
      {values.map((val, index) => (
        <CustomCircularProgress
          key={`bg-${index}`}
          variant="determinate"
          value={100}
          size={160 - index * 35}
          sx={{
            position: 'absolute',
          }}
        />
      ))}
      {values.map((val, index) => (
        <CircularProgressFilled
          key={`fg-${index}`}
          variant="determinate"
          value={val.value}
          size={160 - index * 35}
          color={val.color as any}
          sx={{
            position: 'absolute',
          }}
        />
      ))}
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" component="div" color="textSecondary">
          {valorExibir}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithMultipleLabels;
