import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { useIdiomasActions } from '../../../states/idiomas/idioma.actions';
import { IIdioma, IIdiomaConfig, INivelFluenciaConfig } from '../../../commons/types/crm';
import { useConfigNivelFluenciasActions } from '../../../states/configNivelFluencia/configNivelFluencia.actions';
import { useConfigIdiomasActions } from '../../../states/configIdiomas/configIdioma.actions';
import { GridEventListener } from '@mui/x-data-grid';
import { authAtom } from '../../../states/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { SelectStato } from '../../../commons/styleds/styleds';
import TableCrud from '../../../components/TableCrud/TableCrud';
import AlertComponent from '../../../components/Alert/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FeaturedIcon from '../../../assets/iconsUX/Featured icon.png';
import { styled } from 'styled-components';

interface Props {
  novoUsuario: boolean;
}

export interface IIdiomaContato {
  id?: number;
  idNivelFluencia?: number;
  idContato?: number;
  idIdioma?: number;
}

const IdiomasComponent: React.FC = () => {
  const idiomasActions = useIdiomasActions();
  const [idiomas, setIdiomas] = useState<IIdioma[]>([]);
  const configNivelFluenciasActions = useConfigNivelFluenciasActions();
  const [niveisFluencias, setNiveisFluencias] = useState<INivelFluenciaConfig[]>([]);
  const configIdiomasActions = useConfigIdiomasActions();
  const [idiomasConfig, setIdiomasConfig] = useState<IIdiomaConfig[]>([]);

  const auth = useRecoilValue(authAtom);

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    handleEditar(params.row);
  };

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [idTemp, setIdTemp] = useState<IIdiomaContato>({});
  const [open, setOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingGet, setLoadingGet] = useState<boolean>(true);
  const [editar, setEditar] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [idiomaSelected, setIdiomaSelected] = useState<number>();
  const [nivelFluenciaSelected, setNivelFluenciaSelected] = useState<number>();

  useEffect(() => {
    idiomasActions
      .list(false, `?contatoId=${auth?.id}`)
      .then((resp: any) => {
        setLoadingGet(false);
        setIdiomas(resp);
      })
      .catch((err: any) => {
        setLoadingGet(false);
        toast.warn(err);
      });

    configNivelFluenciasActions
      .list()
      .then((resp: any) => {
        setNiveisFluencias(resp);
      })
      .catch((err: any) => toast.warn(err));

    configIdiomasActions
      .list()
      .then((resp: any) => {
        setIdiomasConfig(resp);
      })
      .catch((err: any) => toast.warn(err));
  }, []);

  const handleEditar = (idioma: IIdiomaContato) => {
    setEditar(true);
    console.log(idioma);
    console.log("editar");
    setIdiomaSelected(idioma.idIdioma);
    setNivelFluenciaSelected(idioma.idNivelFluencia);
    setIdAtualizar(idioma.id);
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };

  const openDrawerIdioma = (result: any) => {
    setEditar(false);
    setIdiomaSelected(undefined);
    setNivelFluenciaSelected(undefined);
    setIdAtualizar(undefined);
    setOpen(result);
  };

  const handleApagar = (obj: IIdiomaContato) => {
    setIdTemp(obj);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setIdTemp({});
  };

  const handleSucess = () => {
    setOpenDialog(false);

    idiomasActions
      .delete(idTemp.id!)
      .then(() => {
        const sub = idiomas.filter((f) => f.id !== idTemp.id);
        setIdiomas(sub);
        setIdTemp({});
      })
      .catch((e) => {
        console.error(e);
        toast.error('Erro ao apagar');
      });
  };

  const adicionar = () => {
    if (Number(auth?.id) <= 0) {
      toast.warn('Id do usuário inválido.');
      return;
    }

    const data: IIdiomaContato = {
      idIdioma: idiomaSelected,
      idContato: Number(auth?.id),
      idNivelFluencia: nivelFluenciaSelected,
    };

    if (!idAtualizar) adicionarNovo(data);
    else {
      data.id = idAtualizar;
      editarIdioma(data);
      setEditar(false);
    }
  };

  const adicionarNovo = (data: IIdiomaContato) => {
    idiomasActions
      .create(data!)
      .then((data) => {
        const newList = idiomas.concat(data!);
        setIdiomas(newList);
        openDrawerIdioma(false);
        toast.success('Adicionado com sucesso');
      })
      .finally(() => setLoading(false));
  };

  const editarIdioma = (data: IIdiomaContato) => {
    console.log(data);
    console.log("editar");
    idiomasActions
      .update(data.id!, data)
      .then((data) => {
        const newList = idiomas.filter((f) => f.idIdioma !== data.id);
        setIdiomas(newList.concat(data));
        openDrawerIdioma(false);
        toast.success('Adicionado com sucesso');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Grid xs={12} md={12} sx={{ padding: '40px 0px 0px 0px' }}>
        <TableCrud
          handleClickDeleteRow={handleApagar}
          handleClickEditRow={handleEditar}
          handleClickAdd={openDrawerIdioma}
          labelTitle="Idiomas"
          labelAddButton='Idioma'
          rows={idiomas.map((row: IIdioma) => ({
            id: row.id,
            idNivelFluencia: row.idNivelFluencia,
            idContato: row.idContato,
            idIdioma: row.idIdioma,
            idioma: idiomasConfig.find((f) => f.id == row.idIdioma)?.nome,
            fluencia: niveisFluencias.find((f) => f.id == row.idNivelFluencia)?.nome,
          }))}
          columns={[
            "idioma",
            "fluencia",
          ]}
          columnsLabels={[
            "Idioma",
            "Fluência",
          ]}
          loadingGet={loadingGet}
        />

      </Grid>

      <React.Fragment>
        <Dialog
          open={open}
          onClose={() => openDrawerIdioma(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { borderRadius: 8 }
          }}
        >
          <DialogAlert>
            <Grid sx={{ paddingLeft: "20px" }}>
              <GridIcon>
                <img src={FeaturedIcon} />
              </GridIcon>
            </Grid>
            <DialogTitleDailog id="alert-dialog-title">
            { editar ? 'Alteração de ' : 'Adicionar '} Idioma
            </DialogTitleDailog>
            <DialogContentDailog>
              <DialogContentText id="alert-dialog-description">
                <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', marginTop: '-10px', marginBottom: '20px' }}>
                  <Grid container xs={7} sm={7} md={7} sx={{ display: 'flex', paddingRight: '5px' }}>
                    <Grid xs={12} md={12}>
                      <LabelInput>Consultor</LabelInput>
                      <FormControl required fullWidth variant="outlined" sx={{ margin: '20px 0px' }}>
                        <SelectStato
                          value={idiomaSelected}
                          onChange={(e: any) => setIdiomaSelected(e.target.value)}
                          required
                          label=""
                        >
                          {idiomasConfig &&
                            idiomasConfig!.map((idiomaSelect: IIdiomaConfig, index: number) => (
                              <MenuItem value={idiomaSelect.id} key={index}>
                                {idiomaSelect.nome}
                              </MenuItem>
                            ))}
                        </SelectStato>
                      </FormControl>
                    </Grid>

                  </Grid>
                  <Grid container xs={5} sm={5} md={5} sx={{ display: 'flex', paddingLeft: '5px' }}>
                    <Grid xs={12} md={12}>
                      <LabelInput>Grau de Fluência</LabelInput>
                      <FormControl required fullWidth variant="outlined" sx={{ margin: '20px 0px' }}>
                        <SelectStato
                          value={nivelFluenciaSelected}
                          onChange={(e: any) => setNivelFluenciaSelected(e.target.value)}
                          required
                          label=""
                        >
                          {niveisFluencias &&
                            niveisFluencias!.map((grau: INivelFluenciaConfig, index: number) => (
                              <MenuItem value={grau.id} key={index}>
                                {grau.nome}
                              </MenuItem>
                            ))}
                        </SelectStato>
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContentDailog>
            <DialogActions sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              <ButtonAlertClose variant="outlined" onClick={() => openDrawerIdioma(false)}>
                Cancelar
              </ButtonAlertClose>
              <ButtonAlert variant="contained" onClick={adicionar} autoFocus>
                Salvar
              </ButtonAlert>

            </DialogActions>
          </DialogAlert>
        </Dialog>
      </React.Fragment >

      <AlertComponent
        open={openDialog}
        labelTitle="Deseja excluir este idioma?"
        labelDescription="O idioma será removido permanentemente dos seus dados cadastrados"
        labelBtnClose="Cancelar"
        labelBtnSucess="Excluir"
        handleClose={handleClose}
        handleSucess={handleSucess}
      />
    </>
  );
};

const ButtonAlertClose = styled(Button)`
width: 170px !important;
height: 44px !important;

font-family: Inter !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;

color: #414651 !important;
border: 1px solid #D5D7DA !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

border-radius: 8px !important;
margin-right: 10px !important;
`;

const ButtonAlert = styled(Button)`
width: 170px !important;
height: 44px !important;
padding: 10px 18px 10px 18px !important;
gap: 8px !important;
border-radius: 8px !important;
border: 1px 0px 0px 0px !important;
opacity: 0px !important;

background: #118BD7 !important;
border: 1px solid #118BD7 !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

`;

const DialogAlert = styled(Grid)`
width: 480px !important;
padding: 20px !important;
`;

const DialogTitleDailog = styled(DialogTitle)`
//styleName: Text lg/Semibold !important;
font-family: Inter !important;
font-size: 18px !important;
font-weight: 600 !important;
line-height: 28px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #181D27 !important;
font-size: 22px !important;

`;

const DialogContentDailog = styled(DialogContent)`
//styleName: Text sm/Regular !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #535862 !important;
`;

const GridIcon = styled(Grid)`
width: 60px !important;
height: 60px !important;
gap: 0px !important;
border-radius: 28px !important;
border: 8px 0px 0px 0px !important;
opacity: 0px !important;
Size="lg" !important;
Color="Warning" !important;
Theme="Light circle outline" !important;

display: flex !important;
justify-content: center !important;
align-items: center !important;
`;

const LabelInput = styled(InputLabel)`
//styleName: Text sm/Medium !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
margin-bottom: -15px !important;
margin-top: 20px !important;
`;

export default IdiomasComponent;
