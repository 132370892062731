import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { GridContainer } from '../../commons/styleds/styleds';
import { IArea, IConteudo, IMenu, ISecao } from '../../commons/types/base';
import { history } from '../../helpers/history';
import { useMenusActions } from '../../states/menus/menu.actions';
import { authAtom } from '../../states/usuarios/AuthState';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function createHTMLFromString(htmlString: any) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IParams {
  idMenu?: string;
  idSubMenu?: string;
}

const PaginasDinamicas: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const path = window.location.pathname;
  const [tipoSecao, setTipoSecao] = useState<number>(0);
  const [tipoSecaoTitulo, setTipoSecaoTitulo] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);
  const { idMenu, idSubMenu } = useParams<IParams>();
  const [conteudos, setConteudos] = useState<IConteudo[]>([]);
  const [area, setArea] = useState<IArea>();
  const [secoes, setSecoes] = useState<ISecao[]>([]);
  const [secao, setSecao] = useState<ISecao>();

  const menusActions = useMenusActions();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleChangeNext = () => {
    if (value < 6) setValue(value + 1);
  };

  const handleChangeBack = () => {
    if (value > 0) setValue(value - 1);
  };

  useEffect(() => {
    let url = history?.location.pathname.split('/');

    let pai = url[1];
    let paiId = url[2];
    let secao = url[3];
    let secaoId = url[4];
    let conteudo = url[5];
    let conteudoId = url[6];
    let conteudoFinal = url[7];
    let conteudoFinalId = url[8];

    menusActions
      .get()
      .then((d) => {
        let menus: IMenu = d.resultado;

        if (secao == undefined) {
          setTipoSecao(0);
          setTipoSecaoTitulo(false);

          let area = menus.areas.find((f: any) => f.id == paiId);

          // let conteudos = menus.conteudos.filter((f: any) => {
          //     return f.idAreas?.some((f: any) => f.idParent == paiId)
          // });

          setConteudos(conteudos);
          setArea(area);
        } else if (conteudo == undefined) {
          setTipoSecao(1);
          setTipoSecaoTitulo(false);

          let secoes = menus.secoes.filter((f: any) => f.idArea == secaoId);
          let area = menus.areas.find((f: any) => f.id == secaoId);

          // let conteudos = menus.conteudos.filter((f: any) => {
          //     return f.idAreas?.some((f: any) => f.idParent == paiId) &&
          //         f.idSecaos?.some((f: any) => f.idArea == secaoId)
          // });

          // setConteudos(conteudos);
          setSecoes(secoes);
          setArea(area);
        } else if (conteudoFinal == undefined) {
          setTipoSecao(2);
          setTipoSecaoTitulo(false);

          let area = menus.areas.find((f: any) => f.id == secaoId);
          let secao = menus.secoes.find((f: any) => f.idArea == secaoId);

          let conteudos = menus.conteudos.filter((f: any) => {
            return f.id == conteudoId;
          });

          setConteudos(conteudos);
          setArea(area);
          setSecao(secao);
        } else {
          setTipoSecao(3);
          setTipoSecaoTitulo(true);
          let area = menus.areas.find((f: any) => f.id == secaoId);
          let secao = menus.secoes.find((f: any) => f.idArea == secaoId);

          let conteudos = menus.conteudos.filter((f: any) => {
            return f.id == conteudoFinalId;
          });

          setConteudos(conteudos);
          setArea(area);
          setSecao(secao);
        }
      })
      .catch((erro) => {
        // toast.error(erro);
      });
  }, [auth]);

  return (
    <GridContainer
      container
      direction="row"
      spacing={0}
      sx={{
        display: 'flex',
        padding: '10px 15px 10px 10px',
        margin: '0px 30px !important',
        backgroundColor: 'white',
      }}
    >
      <Grid
        container
        direction="row"
        spacing={0}
        sx={{ paddingTop: '25px', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}
        xs={12}
        md={12}
      >
        <Grid
          container
          xs={12}
          md={12}
          sm={12}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <Grid xs={12} md={12} sm={12} sx={{ borderBottom: '2.5px solid rgb(123 179 53)' }}>
            <Typography sx={{ padding: '10px 0px' }} variant="h4" component="h2">
              {tipoSecaoTitulo ? secao?.nome : area?.nome}
            </Typography>
          </Grid>
          {!tipoSecaoTitulo && (
            <>
              {tipoSecao != 2 ? (
                <Grid
                  container
                  xs={12}
                  md={12}
                  sx={{
                    paddingBottom: '10px',
                    display: 'flex',
                    borderBottom: 'solid 1.5px #dfdfdf',
                  }}
                >
                  <Typography sx={{ padding: '10px' }} variant="caption">
                    {createHTMLFromString(area?.descricao)}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </>
          )}
        </Grid>

        {/* teste 1 {idMenu} <br />
                teste 2 {idSubMenu}	<br /><br />{history?.location.pathname}
                <br /><br /><br /><br /> */}

        {tipoSecao == 0 ? (
          <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
            {conteudos.map((item: IConteudo) => {
              return (
                <>
                  <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
                    {item.titulo}
                  </Grid>
                </>
              );
            })}
          </Grid>
        ) : tipoSecao == 1 ? (
          <Grid container xs={12} md={12} sx={{ padding: '50px 10px', display: 'flex' }}>
            {secoes.map((item: ISecao) => {
              return (
                <>
                  <Grid
                    container
                    xs={12}
                    md={12}
                    sx={{
                      padding: '10px 10px',
                      display: 'flex',
                      borderBottom: 'solid 1.5px #dfdfdf',
                    }}
                  >
                    <Link to={path + '/cursos-gratuitos/1002'}>
                      <IconButton>
                        <ArrowForwardIosIcon
                          sx={{
                            fontSize: '16px',
                            color: '#551a8b',
                          }}
                        />
                      </IconButton>
                      {item.nome}
                    </Link>

                    <Grid
                      container
                      xs={12}
                      md={12}
                      sx={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '30px' }}
                    >
                      {createHTMLFromString(item.descricao)}
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        ) : tipoSecao == 2 ? (
          <>
            <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
              {conteudos.map((item: IConteudo) => {
                return (
                  <>
                    <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        sx={{
                          padding: '10px 10px',
                          display: 'flex',
                          marginTop: '20px',
                          borderBottom: 'solid 1.5px #dfdfdf',
                        }}
                      >
                        <Grid
                          container
                          xs={12}
                          md={12}
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            borderBottom: 'solid 1.5px #dfdfdf',
                          }}
                        >
                          {secao?.nome}
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          md={12}
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            paddingLeft: '30px',
                            marginBottom: '30px',
                          }}
                        >
                          {createHTMLFromString(secao?.descricao)}
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        md={12}
                        sx={{
                          paddingBottom: '10px',
                          display: 'flex',
                          borderBottom: 'solid 1.5px #dfdfdf',
                        }}
                      >
                        {item.imagem && (
                          <>
                            <Grid
                              container
                              xs={2}
                              md={2}
                              sx={{ display: 'flex', padding: '20px 10px' }}
                            >
                              <img src={item.imagem} alt="imagem" width={'100%'} />
                            </Grid>
                          </>
                        )}

                        <Grid
                          container
                          xs={10}
                          md={10}
                          sx={{ display: 'flex', padding: '20px 10px' }}
                        >
                          <Link to={path + '/indicacao-de-cursos-gratuitos/1002'}>
                            {' '}
                            {item.titulo}{' '}
                          </Link>
                          {item.dtPublicacao && (
                            <Grid
                              container
                              xs={12}
                              md={12}
                              sx={{ display: 'flex', flexWrap: 'wrap' }}
                            >
                              <IconButton>
                                <CalendarMonthIcon
                                  sx={{
                                    fontSize: '16px',
                                    color: '#551a8b',
                                  }}
                                />
                              </IconButton>{' '}
                              {item.dtPublicacao?.toLocaleDateString()}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </>
        ) : (
          <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
            {conteudos.map((item: IConteudo) => {
              return (
                <>
                  <Grid container xs={12} md={12} sx={{ paddingBottom: '20px', display: 'flex' }}>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      sx={{
                        paddingBottom: '20px',
                        paddingTop: '25px',
                        display: 'flex',
                        borderBottom: 'solid 1.5px #dfdfdf',
                      }}
                    >
                      <Typography sx={{ padding: '10px 10px' }} variant="h6" component="h2">
                        {item.titulo}
                      </Typography>
                    </Grid>

                    <Grid container xs={12} md={12} sx={{ padding: '10px 20px', display: 'flex' }}>
                      <Grid container xs={6} md={6} sx={{ paddingBottom: '10px', display: 'flex' }}>
                        <Typography sx={{ padding: '10px' }} variant="caption">
                          {createHTMLFromString(item?.texto)}
                        </Typography>
                      </Grid>
                      <Grid container xs={6} md={6} sx={{ padding: '20px 20px', display: 'flex' }}>
                        <img src={item.imagem} alt="imagem" width={'100%'} />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        )}
      </Grid>
    </GridContainer>
  );
};

const ButtonHome = styled(Button)`
  color: white !important;
  border-radius: 10px !important;
`;

export default PaginasDinamicas;
