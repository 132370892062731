import type { IMenuItens } from "../Header";

export const findBreadcrumb = (path: string, structure: IMenuItens[]): any[] | null => {
  for (const page of structure) {
    if (page.link === path) {
      return [page];
    } else if (page.filhos && page.filhos.length > 0) {
      const childBreadcrumb = findBreadcrumb(path, page.filhos);
      if (childBreadcrumb) {
        return [page, ...childBreadcrumb];
      }
    }
  }
  return null;
};