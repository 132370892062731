import { Box, CircularProgress, Typography } from '@mui/material';
import styled from 'styled-components';

interface ICircularProgressWithLabel {
  value: number;
}

const CircularProgressWithLabel: React.FC<ICircularProgressWithLabel> = ({ value }) => {
  return (
    <Box
      position="relative"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="90%"
    >
      <CustomCircularProgress variant="determinate" value={100} size={160} />
      <CircularProgressFilled
        variant="determinate"
        value={value}
        size={160}
        sx={{
          position: 'absolute',
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" component="div" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const CustomCircularProgress = styled(CircularProgress)`
  && {
    thickness: 16;
    color: #e9eaeb;
    & .MuiCircularProgress-circle {
      stroke-linecap: round;
    }
  }
`;

const CircularProgressFilled = styled(CircularProgress)`
  && {
    thickness: 16;
    color: #007bff; // Define a cor preenchida
    & .MuiCircularProgress-circle {
      stroke-linecap: round;
    }
  }
`;

export default CircularProgressWithLabel;
