
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem } from '@mui/material';
import { useGraduacoesActions } from '../../../states/graduacao/graduacao.actions';
import { IGraduacao } from '../../../commons/types/crm';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/usuarios/AuthState';
import { SelectStato, TextFieldStato } from '../../../commons/styleds/styleds';
import TableCrud from '../../../components/TableCrud/TableCrud';
import AlertComponent from '../../../components/Alert/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FeaturedIcon from '../../../assets/iconsUX/Featured icon.png';
import { styled } from 'styled-components';


const FormacaoAcademicaComponent: React.FC = () => {
    const graduacoesActions = useGraduacoesActions();
    const [graduacoes, setGraducoes] = useState<IGraduacao[]>([]);

    const [listGraduacao, setListGraduacao] = useState<any[]>([
        { "id": 1, "nome": "Graduação, Pós, MBA e Especializações" },
        { "id": 2, "nome": "Outros cursos ou seminários/congressos" }
    ]);

    const auth = useRecoilValue(authAtom);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingGet, setLoadingGet] = useState<boolean>(true);
    const [mes, setMes] = useState<string>("");
    const [ano, setAno] = useState<string>("");
    const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [idTemp, setIdTemp] = useState<number>(0);

    const [graduacao, setGraduacao] = useState<IGraduacao>();
    const [selectedGraduacaos, setSelectedGraduacaos] = useState<
        IGraduacao[] | undefined
    >(undefined);


    useEffect(() => {
        graduacoesActions.list(false, `?contatoId=${auth?.id}`)
            .then((resp: any) => {
                setLoadingGet(false);
                setGraducoes(resp)
            })
            .catch((err: any) => {
                toast.warn(err)
                setLoadingGet(false);
            });

    }, []);

    const handleEditar = (graduacao: IGraduacao) => {
        setIdAtualizar(graduacao.id);
        setGraduacao(graduacao);
        setOpen(true);
    };

    const HandleSetFormcao = (result: any) => {
        setGraduacao({ ...graduacao, tipo: result.target.value });
    };

    const openDrawerGraduacao = (result: any) => {
        setGraduacao(undefined);
        setOpen(result);
    };

    const handleApagar = (id: any) => {
        setIdTemp(id);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setIdTemp(0);
    };

    const handleSucess = () => {
        setOpenDialog(false);

        graduacoesActions.delete(idTemp).then((data) => {
            const sub = graduacoes.filter((f) => f.id !== idTemp);
            setGraducoes(sub);
            toast.success('Item excluído');
            setIdTemp(0);

        }).catch((erro) => {

        });
    };

    const adicionarNaLista = () => {
        if (!graduacao?.tipo) {
            toast.warn('Selecione o tipo de formação');
            return;
        }
        setLoading(true);

        let idContato = 0;
        if (Number(auth?.id) > 0)
            graduacao!.IdContato = Number(auth?.id);

        if (graduacao?.id && graduacao?.id > 0)
            graduacoesActions.update(graduacao!.id, graduacao).then((data: IGraduacao) => {
                const newList = graduacoes.filter(g => g.id !== data.id);
                setGraducoes([...newList, data]);
                openDrawerGraduacao(false);
                setLoading(false);
                toast.success('Item editado');
            });
        else
            graduacoesActions.create(graduacao!).then((data: IGraduacao) => {
                const newList = graduacoes.concat(data!);
                setGraducoes(newList);
                openDrawerGraduacao(false);
                setLoading(false);
                toast.success('Item adicionado');
            })
    };

    return (
        <>
            <Grid xs={12} md={12} sx={{ padding: "40px 0px 0px 0px" }}>

                <TableCrud
                    handleClickDelete={handleApagar}
                    handleClickEdit={handleEditar}
                    handleClickAdd={openDrawerGraduacao}
                    labelTitle="Formações Acadêmicas"
                    labelAddButton='Formação Acadêmica'
                    rows={graduacoes.map((row: IGraduacao) =>
                    ({
                        id: row.id,
                        tipo: row.tipo,
                        nome: row.nome,
                        programa: row.programa,
                        graduado: row.graduado,
                        mesGraduacao: row.mesGraduacao,
                        anoGraduacao: row.anoGraduacao,
                        descricao: row.descricao,
                        IdContato: row.IdContato,
                        nomeTipo: listGraduacao.find((f) => f.id == row.tipo)?.nome,
                        mesAnoGraduacao: row.mesGraduacao ? row.mesGraduacao + " / " + row.anoGraduacao : ""
                    }))}
                    columns={[
                        "nomeTipo",
                        "programa",
                        "mesAnoGraduacao",
                    ]}
                    columnsLabels={[
                        "Instituição",
                        "Curso",
                        "Mês/Ano",
                    ]}
                    loadingGet={loadingGet}
                />

            </Grid>

            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={() => openDrawerGraduacao(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: { borderRadius: 8 }
                    }}
                >
                    <DialogAlert>
                        <Grid sx={{ paddingLeft: "20px" }}>
                            <GridIcon>
                                <img src={FeaturedIcon} />
                            </GridIcon>
                        </Grid>
                        <DialogTitleDailog id="alert-dialog-title">
                            {graduacao?.id && graduacao?.id > 0 ? 'Alteração da ' : 'Adicionar '} Formação Acadêmica
                        </DialogTitleDailog>
                        <DialogContentDailog>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', marginTop: '-10px', marginBottom: '10px' }}>

                                    <Grid xs={12} md={12}>
                                        <LabelInput>Tipo Formação</LabelInput>
                                        <FormControl required fullWidth variant='outlined' sx={{ marginTop: "20px" }}>
                                            <SelectStato value={graduacao?.tipo} onChange={HandleSetFormcao} required label=''>
                                                {listGraduacao &&
                                                    listGraduacao!.map(
                                                        (graduacaoMap, index: number) => (
                                                            <MenuItem value={graduacaoMap.id} key={index}>
                                                                {graduacaoMap.nome}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </SelectStato>
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12} md={12}>
                                        <LabelInput>Curso</LabelInput>
                                        <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                                            <TextFieldStato
                                                id={"GraduacaoInput"}
                                                type="text"
                                                label=""
                                                value={graduacao?.nome}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setGraduacao({ ...graduacao, nome: event.target.value });
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12} md={12}>
                                        <LabelInput>Instituição</LabelInput>
                                        <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                                            <TextFieldStato
                                                id={"GraduacaoInput"}
                                                type="text"
                                                label=""
                                                value={graduacao?.programa}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setGraduacao({ ...graduacao, programa: event.target.value });
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>


                                    <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setGraduacao({ ...graduacao, graduado: event.target.checked });
                                                    }}
                                                    checked={graduacao?.graduado}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label='Cursando'
                                        />
                                    </FormControl>

                                    <InputLabel sx={{ 'marginTop': '20px' }}>Graduação em:</InputLabel>
                                    <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                                        <Grid item xs={6} md={6} style={{ display: 'flex', paddingRight: '5px' }}>
                                            <Grid xs={12} md={12}>
                                                <LabelInput>Mês</LabelInput>
                                                <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px', 'marginRight': '0px' }}>
                                                    <TextFieldStato
                                                        id={"GraduacaoInput"}
                                                        type="number"
                                                        label=""
                                                        value={graduacao?.mesGraduacao}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setGraduacao({
                                                                ...graduacao,
                                                                mesGraduacao: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ display: 'flex', paddingLeft: '5px' }}>
                                            <Grid xs={12} md={12}>
                                                <LabelInput>Ano</LabelInput>
                                                <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                                                    <TextFieldStato
                                                        id={"GraduacaoInput"}
                                                        type="number"
                                                        label=""
                                                        value={graduacao?.anoGraduacao}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setGraduacao({
                                                                ...graduacao,
                                                                anoGraduacao: Number(event.target.value)
                                                            });
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid xs={12} md={12}>
                                        <LabelInput>Descrição do Curso ou Certificações</LabelInput>
                                        <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                                            <TextFieldStato
                                                sx={{ height: 90 }}
                                                id={"GraduacaoInput"}
                                                type="text"
                                                label=""
                                                value={graduacao?.descricao}
                                                multiline
                                                maxRows={4}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setGraduacao({ ...graduacao, descricao: event.target.value });
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContentDailog>
                        <DialogActions sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <ButtonAlertClose variant="outlined" onClick={() => openDrawerGraduacao(false)}>
                                Cancelar
                            </ButtonAlertClose>
                            <ButtonAlert variant="contained" onClick={adicionarNaLista} autoFocus>
                                Salvar
                            </ButtonAlert>

                        </DialogActions>
                    </DialogAlert>
                </Dialog>
            </React.Fragment >

            <AlertComponent
                open={openDialog}
                labelTitle="Deseja excluir esta formação acadêmica?"
                labelDescription="A formação acadêmica será removido permanentemente dos seus dados cadastrados"
                labelBtnClose="Cancelar"
                labelBtnSucess="Excluir"
                handleClose={handleClose}
                handleSucess={handleSucess}
            />
        </>
    );
};

const ButtonAlertClose = styled(Button)`
width: 170px !important;
height: 44px !important;

font-family: Inter !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;

color: #414651 !important;
border: 1px solid #D5D7DA !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

border-radius: 8px !important;
margin-right: 10px !important;
`;

const ButtonAlert = styled(Button)`
width: 170px !important;
height: 44px !important;
padding: 10px 18px 10px 18px !important;
gap: 8px !important;
border-radius: 8px !important;
border: 1px 0px 0px 0px !important;
opacity: 0px !important;

background: #118BD7 !important;
border: 1px solid #118BD7 !important;
box-shadow: 0px 1px 2px 0px #0A0D120D !important;

`;

const DialogAlert = styled(Grid)`
width: 480px !important;
padding: 20px !important;
`;

const DialogTitleDailog = styled(DialogTitle)`
//styleName: Text lg/Semibold !important;
font-family: Inter !important;
font-size: 18px !important;
font-weight: 600 !important;
line-height: 28px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #181D27 !important;
font-size: 22px !important;

`;

const DialogContentDailog = styled(DialogContent)`
//styleName: Text sm/Regular !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #535862 !important;
`;

const GridIcon = styled(Grid)`
width: 60px !important;
height: 60px !important;
gap: 0px !important;
border-radius: 28px !important;
border: 8px 0px 0px 0px !important;
opacity: 0px !important;
Size="lg" !important;
Color="Warning" !important;
Theme="Light circle outline" !important;

display: flex !important;
justify-content: center !important;
align-items: center !important;
`;

const LabelInput = styled(InputLabel)`
//styleName: Text sm/Medium !important;
font-family: Inter !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 20px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
margin-bottom: -15px !important;
margin-top: 20px !important;
`;

export default FormacaoAcademicaComponent;
