import { styled } from 'styled-components';

export interface IBannerCard {
  children?: any;
  headerTitle: string;
}

const BannerCard: React.FC<IBannerCard> = ({ headerTitle, children }) => {
  return (
    <DivCard>
      <div style={{ height: '100%', width: '100%', borderRadius: '20px' }}>{children}</div>
    </DivCard>
  );
};

const DivCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 100%;
  height: 456px;
  background: #ffffff;
  border: 1px solid #e9eaeb;
  box-shadow:
    0px 12px 16px -4px rgba(10, 13, 18, 0.08),
    0px 4px 6px -2px rgba(10, 13, 18, 0.03);
  border-radius: 16px;
`;

const HeaderCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;

  width: 384px;
  height: 40px;

  border-radius: 16px 16px 0px 0px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export default BannerCard;
