import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { styled } from 'styled-components';
import React from 'react';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer } from '../../commons/styleds/styleds';
import Box from '@mui/material/Box';
import { Link, useParams } from 'react-router-dom';
import { history } from '../../helpers/history';
import { useMenusActions } from '../../states/menus/menu.actions';
import { IArea, IConteudo, IMenu, ISecao } from '../../commons/types/base';
import { Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export function createHTMLFromString(htmlString: any) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

interface IParams {
  id?: string;
  secaoId?: string;
  menuId?: string;
}

const ConteudoComponent: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const [value, setValue] = React.useState(0);
  const { id, secaoId } = useParams<IParams>();
  const [conteudo, setConteudo] = useState<IConteudo>();
  const [area, setArea] = useState<IArea>();
  const [secao, setSecao] = useState<ISecao>();

  const menusActions = useMenusActions();

  useEffect(() => {
    menusActions
      .get()
      .then((d) => {
        let menus: IMenu = d.resultado;

        if (secaoId != undefined) {
          const secao = menus.secoes.find((f) => f.id == Number(secaoId));
          const area = menus.areas.find((f) => f.id == secao?.idArea!);
          setArea(area);
          setSecao(secao);
        }

        let conteudo = menus.conteudos.find((f) => f.id == Number(id));
        setConteudo(conteudo);
      })
      .catch(() => {
      });
  }, [id]);

  return (
    <>
      <GridContainer
        container
        direction="row"
        spacing={0}
        sx={{
          display: 'flex',
          padding: '10px 15px 10px 10px',
          margin: '0px 30px !important',
          backgroundColor: 'white',
        }}
      >
        <Grid
          container
          direction="row"
          spacing={0}
          sx={{ paddingTop: '25px', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}
          xs={12}
          md={12}
        >
          <Grid
            container
            xs={12}
            md={12}
            sm={12}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            {secaoId != undefined && (
              <>
                <Grid xs={12} md={12} sm={12} sx={{ borderBottom: '2.5px solid rgb(255 191 0)' }}>
                  <Typography sx={{ padding: '10px 0px' }} variant="h4" component="h2">
                    {secao?.nome}
                  </Typography>
                </Grid>

                <Grid
                  container
                  xs={12}
                  md={12}
                  sx={{ paddingBottom: '10px', display: 'flex', borderBottom: 'solid 1.5px #dfdfdf' }}
                >
                  <Typography sx={{ padding: '10px' }} variant="caption">
                    {createHTMLFromString(area?.descricao)}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex', borderBottom: 'solid 1.5px #dfdfdf' }}>
            <Grid container xs={12} md={12} sx={{ paddingBottom: '20px', display: 'flex' }}>
              <Grid
                container
                xs={12}
                md={12}
                sx={{
                  paddingBottom: '20px',
                  paddingTop: '25px',
                  display: 'flex',
                  borderBottom: 'solid 1.5px #dfdfdf',
                }}
              >
                <Typography sx={{ padding: '10px 10px' }} variant="h6" component="h2">
                  {conteudo?.titulo}
                </Typography>
              </Grid>

              <Grid container xs={12} md={12} sx={{ padding: '10px 20px', display: 'flex' }}>
                <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
                  <Typography sx={{ padding: '10px' }} variant="caption">
                    {createHTMLFromString(conteudo?.texto)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridContainer>


      {conteudo?.tbAnexosConteudo && conteudo?.tbAnexosConteudo.length ? (
        <Grid
          container
          direction="column"
          spacing={0}
          sx={{
            display: 'flex',
            padding: '10px 15px 10px 10px',
            margin: '0px 30px !important',
            backgroundColor: 'white',
          }}
        >
          {conteudo?.tbAnexosConteudo.map((c: any, i: number) => (
            <Typography
              key={i}
              sx={{ padding: '10px', display: 'block' }}
              variant="caption"
            >
              <a href={c.anexo} target="_blank" rel="noopener noreferrer">
                {i + 1}º - Anexo
              </a>
            </Typography>
          ))}
        </Grid>
      ) : null}
    </>
  );
};

const ButtonHome = styled(Button)`
  color: white !important;
  border-radius: 10px !important;
`;

export default ConteudoComponent;
